// import img1 from '@/asset/看云科技/技术01.png'
// import img2 from '@/asset/看云科技/技术02.png'
// import img3 from '@/asset/看云科技/技术03.png'
// import img4 from '@/asset/看云科技/技术04.png'
// import img5 from '@/asset/看云科技/技术05.png'
// import imgBg from '@/asset/看云科技/技术底图.jpg'

import { isMobile } from '@/utils/common'

export const techImgs = isMobile() ? {
  img1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/kQtAwI6J9mm9wTcurKAiQ.png',
  img2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/5Lo5h2bvGeb1FcRsGV1RV2.png',
  img3: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/XgEBCwd9m5WRsJmaFhEC6.png',
  img4: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/Or0RbdvjgL4VkLvBFT1Hfy.png',
  imgBg: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/5Ors2aQZu6jv7atRVgwy1.png',
} : {
  img1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/x7vSJfNXurqz9YzibpTWe4.png',
  img2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/0aTfztOJR42KGjXw7yWVi7.png',
  img3: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/GEcqEFUmVXVXpoW4H6eO.png',
  img4: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/VVrLz8GOLp7aAeuctkmc.png',
  img5: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/QEdUFCtDsstUPUUd6WwcLN.png',
  imgBg: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/YpAcDzV8hqEoJEGdOaeUIV.jpg',
}
