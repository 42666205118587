import React from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Home from '@/pages/home'
import { Route, Routes } from 'react-router-dom'
import lessModule from './App.module.less'

gsap.registerPlugin(ScrollTrigger)

export default function App(props: any) {
  return (
    <div className={lessModule.app}>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  )
}
