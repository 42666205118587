import React from 'react'
import './style.scss'

function BannerCircle(props: any, ref: React.ForwardedRef<HTMLDivElement>) {
  return (
    <div className={`banner-circle ${props.className}`} ref={ref}>
      <div className="banner-arc" />
    </div>
  )
}

export default React.forwardRef(BannerCircle)
