import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import App from '@/App'
import 'normalize.css'
// import VConsole from 'vconsole'
import './styles/page-base.less'
import { adjustRem, isMobile } from './utils/common'

adjustRem()

if (isMobile() && process.env.REACT_APP_ENV === 'development') {
  // eslint-disable-next-line no-new
  // new VConsole({ theme: 'dark' })
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
)
