import { throttle } from 'lodash-es'
import { bussinessImgs } from '@/constants/imgs-src/bussiness'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { getElementMobileSize } from '@/utils/common'
// import { LocomotiveScrollEvent } from '@/pages/home'
// import JellyEffect from '@/utils/jelly'
import lessModule from './index.module.less'

const waterfallRow1 = [
  [
    {
      // 高 / 宽 的比例
      ratio: 222 / 356,
      width: 356,
      height: 222,
      src: bussinessImgs.feixiang,
      link: 'https://www.feixiangxingqiu.com/',
    },
  ],
]

const waterfallRow2 = [
  [
    {
      // 高 / 宽 的比例
      ratio: 214 / 165,
      width: 165,
      height: 214,
      src: bussinessImgs.tiku,
      link: 'https://www.yuantiku.com/',
    },
    {
      // 高 / 宽 的比例
      ratio: 207 / 165,
      width: 165,
      height: 207,
      src: bussinessImgs.sky,
      link: 'https://mp.weixin.qq.com/s/2a4xraPYouur2ZKAPgbFYA',
    },
  ],
  [
    {
      // 高 / 宽 的比例
      ratio: 274 / 186,
      width: 186,
      height: 274,
      src: bussinessImgs.haitun,
      link: 'https://www.haitunzixi.com/',
    },
    {
      // 高 / 宽 的比例
      ratio: 147 / 186,
      width: 186,
      height: 147,
      src: bussinessImgs.kousuan,
      link: 'https://xiaoyuankousuan.com/',
    },
  ],
]

const waterfallRow3 = [
  [
    {
      // 高 / 宽 的比例
      ratio: 223 / 356,
      width: 356,
      height: 223,
      src: bussinessImgs.banma,
      link: 'https://banmaapp.com/',
    },
  ],
]

const waterfallRow4 = [
  [
    {
      // 高 / 宽 的比例
      ratio: 284 / 175,
      width: 175,
      height: 284,
      src: bussinessImgs.coffee,
      link: 'https://mp.weixin.qq.com/s/l-uvfRJhuObI7OkzUxUI6g',
    },
  ],
  [
    {
      // 高 / 宽 的比例
      ratio: 140 / 177,
      width: 177,
      height: 140,
      src: bussinessImgs.biancheng,
      link: 'https://www.ybccode.com/',
    },
    {
      // 高 / 宽 的比例
      ratio: 140 / 177,
      width: 177,
      height: 140,
      src: bussinessImgs.moli,
      link: 'https://www.moliyuezi.com/',
    },
  ],
]

const waterfallRow5 = [
  [
    {
      // 高 / 宽 的比例
      ratio: 222 / 356,
      width: 356,
      height: 222,
      src: bussinessImgs.fudao,
      link: 'https://www.yuanfudao.com/',
    },
  ],
]

const waterfallList = [
  waterfallRow1,
  waterfallRow2,
  waterfallRow3,
  waterfallRow4,
  waterfallRow5,
]

export default function BusinessImgMobile() {
  const [imgGap, setImgGap] = useState('5px')
  const [wrapHeight, setWrapHeight] = useState('auto')
  const wrapRef = useRef<HTMLDivElement>(null)
  const canAnimate = useRef(true)
  const clearFnRef = useRef<(() => void)[]>([])

  const [, forceUpdate] = useState({})

  const onImgContentRef = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      setWrapHeight(`${el.offsetHeight}px`)
    }
  }, [])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      setImgGap(getElementMobileSize(5))
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    const fn = () => {
      // console.log('fn')
      while (clearFnRef.current.length) {
        const c = clearFnRef.current.pop()
        c && c()
      }
      for (let rowIndex = 0; rowIndex < waterfallList.length; rowIndex++) {
        for (let colIndex = 0; colIndex < waterfallList[rowIndex].length; colIndex++) {
          for (let imgIndex = 0; imgIndex < waterfallList[rowIndex][colIndex].length; imgIndex++) {
            const img = waterfallList[rowIndex][colIndex][imgIndex]
            const timeline = gsap.timeline()
            timeline.fromTo(
              `.${lessModule.row}-${rowIndex + 1} .${lessModule.col}-${colIndex + 1} .${lessModule.img}-${imgIndex + 1}`,
              {
                transform: `translate3d(0, ${img.height / 2}px, 0)`,
                opacity: 0,
              },
              {
                transform: 'translate3d(0, 0, 0)',
                ease: 'none',
                opacity: 1,
              },
            )
            const trigger = ScrollTrigger.create({
              animation: timeline,
              trigger: `.${lessModule.row}-${rowIndex + 1} .${lessModule.col}-${colIndex + 1} .${lessModule.img}-${imgIndex + 1}`,
              start: `top-=${img.height / 2}px bottom`,
              // end: `bottom-=${img.height / 2}px bottom`,
              end: 'bottom bottom',
              scrub: true,
            })
            const c = () => {
              timeline.kill()
              trigger.kill()
            }
            clearFnRef.current.push(c)
          }
        }
      }
      return clearFnRef.current
    }
    const onResize = throttle(() => {
      forceUpdate({})
      setTimeout(() => {
        // console.log('canAnimate.current', canAnimate.current)
        if (canAnimate.current) {
          fn()
        }
      }, 100)
    }, 300)
    onResize()
    window.addEventListener('resize', onResize, false)
    const clearList = clearFnRef.current
    return () => {
      clearList.forEach((c) => c())
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  return (
    <div
      className={`${lessModule.imgWrap}`}
      ref={wrapRef}
      data-scroll
      data-scroll-offset="-300,0"
      data-scroll-call={`preloadImg, businessImg, ${lessModule.imgWrap}`}
      style={{
        height: wrapHeight,
      }}
    >
      <div
        // style={{
        //   height: '100vh',
        // }}
        data-scroll
        // data-scroll-sticky
        // data-scroll-target={`.${lessModule.imgWrap}`}
      >
        <div
          className={lessModule.imgContent}
          data-scroll
          ref={(el) => onImgContentRef(el)}
        >
          {
            waterfallList.map((row, index) => (
              <div
                className={`${lessModule.row}-${index + 1} ${lessModule.row}`}
                style={{
                  marginBottom: index === waterfallList.length - 1 ? 0 : imgGap,
                }}
                key={index}
              >
                {
                  row.map((col, colIndex) => (
                    <div
                      key={colIndex}
                      className={`${lessModule.col}-${colIndex + 1} ${lessModule.col}`}
                      style={{
                        marginRight: colIndex === row.length - 1 ? 0 : imgGap,
                        width: `${col[0].width / 100}rem`,
                      }}
                    >
                      {
                        col.map((img, imgIndex) => (
                          <img
                            // src={img.src}
                            data-src={img.src}
                            onClick={() => window.open(img.link, '_blank')}
                            alt=""
                            className={`${lessModule.img}-${imgIndex + 1} ${lessModule.img}`}
                            key={imgIndex}
                            style={{
                              marginBottom: imgIndex === col.length - 1 ? 0 : imgGap,
                              width: `${img.width / 100}rem`,
                              height: `${img.height / 100}rem`,
                            }}
                          />
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
