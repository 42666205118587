import { useCallback, useEffect, useRef } from 'react'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

/**
 * 在横向滚动时禁止纵向滚动
 * @param ele
 */
export function useTouchX(ele?: React.RefObject<HTMLDivElement>) {
  const timer = useRef(0)
  const touchStartPos = useRef({ x: 0, y: 0 })

  const onTouchStart = useCallback((e: TouchEvent) => {
    touchStartPos.current = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    }
  }, [])

  const onTouchMove = useCallback((e: TouchEvent) => {
    const x = e.touches[0].pageX
    const y = e.touches[0].pageY
    const diffX = Math.abs(x - touchStartPos.current.x)
    const diffY = Math.abs(y - touchStartPos.current.y)
    // diffX 更多，说明是横向滑动，禁掉默认行为，防止纵向滚动
    if (diffX > diffY) {
      e.stopPropagation()
      e.preventDefault()
    }
  }, [])

  const touchBind = useCallback((element: HTMLDivElement) => {
    element.addEventListener('touchstart', onTouchStart, { passive: false, capture: false })
    element.addEventListener('touchmove', onTouchMove, { passive: false, capture: false })
    return () => {
      element?.removeEventListener('touchstart', onTouchStart, { capture: false })
      element?.removeEventListener('touchmove', onTouchMove, { capture: false })
    }
  }, [onTouchMove, onTouchStart])

  useEffect(() => {
    const element = ele?.current
    const init = () => {
      window.clearTimeout(timer.current)
      if (element) {
        element.addEventListener('touchstart', onTouchStart, { passive: false, capture: false })
        element.addEventListener('touchmove', onTouchMove, { passive: false, capture: false })
      } else {
        timer.current = window.setTimeout(() => {
          init()
        }, 100)
      }
    }
    init()
    return () => {
      element?.removeEventListener('touchstart', onTouchStart, { capture: false })
      element?.removeEventListener('touchmove', onTouchMove, { capture: false })
      window.clearTimeout(timer.current)
    }
  }, [ele, onTouchMove, onTouchStart])

  return {
    touchBind,
  }
}
