// import news1 from '@/asset/看云资讯/新闻稿1.jpg'
// import news2 from '@/asset/看云资讯/新闻稿2.jpg'
// import news3 from '@/asset/看云资讯/新闻稿3.jpg'

export const newsImgs = {
  news1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/jsuacG7sbSPVXihPUWpxWI.jpeg',
  news2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/FeDUwz70kb075nmXkVhNo.jpg',
  news3: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/4EfCE5DVxFCY7nmWtQA.png',
  news4: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/qNA9IZudPY1kO7WLLbUeY2.png',
  news5: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/j5RmZqNA4sShkZBlhH1kD.jpeg',
  news6: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/1BuQBk0tDyjSBIPdlRfET6.jpg',
  news7: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/K7YVRQSkgvFxnNX1XJAnO.png',
}
