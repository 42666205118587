import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as LogoImg } from '@/asset/logo.svg'
import { debounce, throttle } from 'lodash-es'
import { footerText } from '@/constants/texts'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ResizeObserver from 'resize-observer-polyfill'
import { cloudImgs } from '@/constants/imgs-src/cloud'
import { getElementTranslateY, isMobile } from '@/utils/common'
import { LocomotiveScrollEvent } from '@/pages/home'
import lessModule from './index.module.less'

const cloudDuration = 24

export default function Footer() {
  const [contentHeight, setContentHeight] = useState('auto')
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const contentRef = useRef<HTMLDivElement>(null)
  const cloud1WrapRef = useRef<HTMLDivElement>(null)
  const cloud2WrapRef = useRef<HTMLDivElement>(null)

  const minFooterHeight = useMemo(() => Math.max(parseInt(contentHeight), windowHeight), [contentHeight, windowHeight])

  useEffect(() => {
    // 动画
    const fn = () => {
      // 左边的云
      const cloud1Timeline = gsap.timeline()
      cloud1Timeline.fromTo(
        `.${lessModule.cloud1}`,
        {
          transform: 'translate(0, -50%)',
        },
        {
          transform: 'translate(0, 0)',
          ease: 'none',
        },
      )
      const clound1Trigger = ScrollTrigger.create({
        animation: cloud1Timeline,
        trigger: `.${lessModule.footerContent}`,
        start: 'bottom bottom',
        end: 'bottom+=100% bottom',
        scrub: true,
      })
      // 右边的云
      const cloud2Timeline = gsap.timeline()
      cloud2Timeline.fromTo(
        `.${lessModule.cloud2}`,
        {
          transform: 'translate(0, -50%)',
        },
        {
          transform: 'translate(0, 0)',
          ease: 'none',
        },
      )
      const clound2Trigger = ScrollTrigger.create({
        animation: cloud2Timeline,
        trigger: `.${lessModule.footerContent}`,
        start: 'bottom bottom',
        end: 'bottom+=100% bottom',
        scrub: true,
      })
      return () => {
        clound1Trigger.kill()
        clound2Trigger.kill()
      }
    }
    let clear = () => {}
    let resizeObserver: ResizeObserver

    const observe = () => {
      if (!contentRef.current) {
        setTimeout(() => {
          observe()
        }, 100)
      } else {
        const ele = contentRef.current
        const cb = debounce((entries) => {
          for (const entry of entries) {
            if (entry.target) {
              // const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize
              setContentHeight(getComputedStyle(entry.target).height)
            } else {
              console.log('not entry')
            }
          }
        }, 200)
        resizeObserver = new ResizeObserver(cb)

        resizeObserver.observe(ele)
        const clearFn = fn()
        clear = () => {
          clearFn()
          resizeObserver.unobserve(ele)
        }
      }
    }
    observe()

    let cloud1WrapTimeline: gsap.core.Timeline | undefined
    let cloud2WrapTimeline: gsap.core.Timeline | undefined

    const cancelCloudAnimation = () => {
      if (cloud1WrapTimeline) {
        cloud1WrapTimeline.pause()
        cloud1WrapTimeline.kill()
      }
      if (cloud2WrapTimeline) {
        cloud2WrapTimeline.pause()
        cloud2WrapTimeline.kill()
      }
    }

    const onScroll = throttle(() => {
      const { value: scrollEvent } = LocomotiveScrollEvent
      if (scrollEvent) {
        const { scroll: { y } } = scrollEvent
        const top = y
        const limitTop = scrollEvent.limit.y
        if (top >= limitTop - 5) {
          if (cloud1WrapTimeline?.isActive() || cloud2WrapTimeline?.isActive()) {
            return
          }
          cloud1WrapTimeline = gsap.timeline()
          if (cloud1WrapRef.current) {
            const offsetH = parseInt(getComputedStyle(cloud1WrapRef.current).height)
            const baseTransForm = offsetH * 0.88
            const cloud1TopEdge = isMobile() ? 'translate(0, -51%)' : 'translate(0, -70%)'
            const cloud1BottomEdge = isMobile() ? 'translate(0, 112%)' : 'translate(0, 108%)'
            cloud1WrapTimeline.to(
              `.${lessModule.cloud1Wrap}`,
              {
                transform: cloud1TopEdge,
                ease: 'none',
                duration: cloudDuration * (baseTransForm + getElementTranslateY(cloud1WrapRef.current)) / baseTransForm,
                onComplete() {
                  cloud1WrapTimeline?.fromTo(
                    `.${lessModule.cloud1Wrap}`,
                    {
                      transform: cloud1BottomEdge,
                    },
                    {
                      transform: cloud1TopEdge,
                      ease: 'none',
                      duration: cloudDuration * 2,
                      repeat: -1,
                    },
                  )
                },
              },
            )
          }
          cloud2WrapTimeline = gsap.timeline()
          if (cloud2WrapRef.current) {
            const offsetH = parseInt(getComputedStyle(cloud2WrapRef.current).height)
            const baseTransForm = offsetH * 0.88
            const cloud2TopEdge = isMobile() ? 'translate(0, -50%)' : 'translate(0, -100%)'
            const cloud2BottomEdge = isMobile() ? 'translate(0, 165%)' : 'translate(0, 130%)'
            cloud2WrapTimeline.to(
              `.${lessModule.cloud2Wrap}`,
              {
                transform: cloud2TopEdge,
                ease: 'none',
                duration: cloudDuration * 1.5 * (baseTransForm + getElementTranslateY(cloud2WrapRef.current)) / baseTransForm,
                onComplete() {
                  cloud2WrapTimeline?.fromTo(
                    `.${lessModule.cloud2Wrap}`,
                    {
                      transform: cloud2BottomEdge,
                    },
                    {
                      transform: cloud2TopEdge,
                      ease: 'none',
                      duration: cloudDuration * 1.5 * 2,
                      repeat: -1,
                    },
                  )
                },
              },
            )
          }
        } else {
          cancelCloudAnimation()
        }
      }
    }, 100)

    if (LocomotiveScrollEvent.scroller) {
      LocomotiveScrollEvent.scroller.on('scroll', onScroll)
    }

    const onResize = debounce(() => {
      cancelCloudAnimation()
      onScroll()
    }, 200)

    window.addEventListener('resize', onResize, false)
    onResize()

    return () => {
      clear && clear()
      window.removeEventListener('resize', onResize, false)
      if (LocomotiveScrollEvent.scroller) {
        // @ts-ignore
        LocomotiveScrollEvent.scroller.off('scroll', onScroll)
      }
    }
  }, [])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      setWindowHeight(window.innerHeight)
    }, 200, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  return (
    <div
      className={`${lessModule.footer} ${isMobile() ? lessModule.mobile : ''}`}
      style={{ height: contentHeight }}
    >
      <div
        className={lessModule.fixWrap}
        style={{
          height: `calc(${contentHeight} + ${minFooterHeight}px)`,
          top: `-${minFooterHeight}px`,
        }}
        data-scroll
      >
        <div
          className={lessModule.fixItem}
          data-scroll
          data-scroll-sticky
          data-scroll-target={`.${lessModule.fixWrap}`}
          style={{
            height: `${minFooterHeight}px`,
          }}
        >
          <div
            className={lessModule.fixInner}
            style={{
              height: `${minFooterHeight}px`,
            }}
          >
            <div />
            <div
              className={lessModule.footerContent}
              ref={contentRef}
            >
              <div
                className={lessModule.cloud1Wrap}
                ref={cloud1WrapRef}
              >
                <img
                  src={cloudImgs.yun1}
                  className={lessModule.cloud1}
                  alt=""
                />
              </div>
              <div
                className={lessModule.cloud2Wrap}
                ref={cloud2WrapRef}
              >
                <img
                  src={cloudImgs.yun2}
                  className={lessModule.cloud2}
                  alt=""
                />
              </div>

              <div className={lessModule.left}>
                <div
                  className={lessModule.bigText}
                >
                  <div className={lessModule.line}>行到水穷处</div>
                  <div className={lessModule.line}>
                    坐
                    <span className={lessModule.hightLight}>看云</span>
                    起时
                  </div>
                </div>
                <div
                  className={lessModule.logo}
                >
                  <LogoImg />
                </div>
              </div>
              <div className={lessModule.right}>
                <div className={lessModule.links}>
                  {
                    footerText.map((column, index) => (
                      <div
                        className={lessModule.linkColumn}
                        key={index}
                      >
                        {
                          column.map((item, cIndex) => (
                            <span
                              className={lessModule.linkItem}
                              key={`${index}-${cIndex}`}
                              onClick={() => {
                                window.open(item.link, '_blank')
                              }}
                            >{item.text}</span>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
                <div className={lessModule.extraInfo}>
                  <div
                    className={lessModule.sepLine}
                  />
                  <div
                    className={lessModule.copyRight}
                    style={!isMobile() ? { display: 'flex', justifyContent: 'space-between' } : {}}
                  >
                    <div>
                      <div className={lessModule.line}>北京猿力科技有限公司版权所有</div>
                      <div
                        className={`${lessModule.line} ${lessModule.lineLink}`}
                        onClick={() => {
                          window.open('https://beian.miit.gov.cn/', '_blank')
                        }}
                      >京ICP备2022027389号-1</div>
                    </div>

                    <div className={lessModule.line} style={isMobile() ? { marginTop: '0.18rem' } : {}}>廉政举报邮箱：jubao@kanyun.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
