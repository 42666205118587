import Banner from '@/components/banner'
import BusinessTitle from '@/components/business/title'
import BusinessImg from '@/components/business/img'
import Experience from '@/components/experience'
import News from '@/components/news'
import Tech from '@/components/tech'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ScrollTrigger from 'gsap/ScrollTrigger'
import LocomotiveScroll from 'locomotive-scroll'
import 'locomotive-scroll/dist/locomotive-scroll.min.css'
import { debounce } from 'lodash-es'
import SocialValue from '@/components/social-value'
import Footer from '@/components/footer'

import toggleLeftActive from '@/asset/icons/toggle-left-active.svg'
import toggleRightActive from '@/asset/icons/toggle-right-active.svg'
// import { getElementSize } from '@/utils/common'
import { bannerText, experienceText, newsText, socialValueText, techText } from '@/constants/texts'
import { adjustRem, isMobile, preloadImgFilterExist } from '@/utils/common'
import NewsMobile from '@/components/mobile-components/news'
import { bussinessImgs } from '@/constants/imgs-src/bussiness'
import TechMobile from '@/components/mobile-components/tech'
import BusinessImgMobile from '@/components/mobile-components/business-img'
import ExperienceMobile from '@/components/mobile-components/experience'
import lessModule from './index.module.less'

type ScrollEventType = {
  value?: LocomotiveScroll.OnScrollEvent,
  scroller?: LocomotiveScroll,
}

export const LocomotiveScrollEvent: ScrollEventType = {
  value: undefined,
  scroller: undefined,
}

const preloadImgMap = {
  businessImg: Object.keys(bussinessImgs).map((key) => (bussinessImgs as any)[key]),
  expImg: experienceText.map((item) => item.img),
  techImg: techText.map((item) => item.img),
  socialImg: socialValueText.map((item) => item.img),
  bannerImg: bannerText.flat(1).map((item) => item.bg),
  newsImg: newsText.map((item) => item.img),
}

type preloadImgMapKey = keyof (typeof preloadImgMap)

const preloadImgRecord: Partial<{
  [key in preloadImgMapKey]: boolean
}> = {}
Object.keys(preloadImgMap).forEach((key) => {
  preloadImgRecord[key as preloadImgMapKey] = false
})

preloadImgFilterExist(preloadImgMap.bannerImg)
preloadImgFilterExist(preloadImgMap.newsImg)
preloadImgFilterExist([toggleLeftActive, toggleRightActive])

const initIsMobile = isMobile()

export default function Home() {
  const wrapRef = useRef<HTMLDivElement>(null)
  const imgWrapRef = useRef<HTMLDivElement>(null)
  const [hasInit, setHasInit] = useState(false)

  const init = useCallback(() => {
    let scroller: LocomotiveScroll
    let clearFN = () => {}
    if (!wrapRef.current) {
      setTimeout(() => {
        init()
      }, 100)
    } else {
      scroller = new LocomotiveScroll({
        el: wrapRef.current,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        tablet: {
          smooth: true,
          breakpoint: 1024,
        },
      })
      LocomotiveScrollEvent.scroller = scroller

      scroller.on('call', ([type, data, selector]) => {
        if (type === 'preloadImg') {
          // console.log('preloadImg', data, document.querySelector(`.${selector}`))
          preloadImgFilterExist(preloadImgMap[data as preloadImgMapKey])

          if (!preloadImgRecord[data as preloadImgMapKey]) {
            try {
              // console.log('imgs', document.querySelector(`.${selector}`)?.querySelectorAll('img[data-src]'))
              document.querySelector(`.${selector}`)?.querySelectorAll('img[data-src]').forEach((img) => {
                img.setAttribute('src', img.getAttribute('data-src') || '')
              })
              preloadImgRecord[data as preloadImgMapKey] = true
              setTimeout(() => {
                scroller.update()
                ScrollTrigger.update()
              }, 100)
            } catch (e) {
              console.warn(e)
            }
          }
        }
      })

      const onResize = debounce(() => {
        adjustRem()
        if (initIsMobile !== isMobile()) {
          window.location.reload()
        }
        setTimeout(() => {
          scroller.update()
          ScrollTrigger.update()
          ScrollTrigger.refresh(true)
        }, 150)
        setTimeout(() => {
          scroller.update()
          ScrollTrigger.update()
          ScrollTrigger.refresh(true)
        }, 350)
      }, 500)
      adjustRem()
      window.addEventListener('resize', onResize)

      scroller.on('scroll', (args) => {
        LocomotiveScrollEvent.value = args
        ScrollTrigger.update()
      })

      ScrollTrigger.scrollerProxy(`.${lessModule.container}`, {
        scrollTop(value) {
          // @ts-ignore
          return arguments.length ? scroller.scrollTo(value, { duration: 0, disableLerp: true }) : scroller.scroll.instance.scroll.y
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: wrapRef.current.style.transform ? 'transform' : 'fixed',
      })

      // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
      ScrollTrigger.addEventListener('refresh', () => {
        scroller.update()
      })
      ScrollTrigger.defaults({ scroller: `.${lessModule.container}` })

      setHasInit(true)
      setTimeout(() => {
        scroller.update()
        ScrollTrigger.update()
      }, 200)

      clearFN = () => {
        window.removeEventListener('resize', onResize)
      }
    }
    return () => {
      scroller?.destroy()
      clearFN()
    }
  }, [])

  useEffect(() => {
    let clear = () => {}
    clear = init()
    return () => {
      clear()
    }
  }, [init])

  return (
    <div
      data-scroll-container
      ref={wrapRef}
      className={`${lessModule.container} scroll-container`}
    >
      {
        hasInit && (
          <>
            <div
              data-scroll
              data-scroll-section
              data-scroll-delay="1500"
              data-scroll-id="Banner"
              className={lessModule.coverBg}
            >
              <Banner />
            </div>
            <div
              className={lessModule.content}
              data-scroll
              data-scroll-section
              data-scroll-delay="1500"
            >
              <div
                // data-scroll
                // data-scroll-section
                data-scroll-id="News"
                className={lessModule.coverBg}
              >
                {
                  isMobile() ? (
                    <NewsMobile />
                  ) : (
                    <News />
                  )
                }
              </div>
              <div
                className={`${lessModule.coverBg}`}
                // data-scroll
                // data-scroll-section
              >
                <div data-scroll-id="Experience" className={lessModule.coverBg}>
                  {
                    isMobile() ? (
                      <ExperienceMobile />
                    ) : (
                      <Experience />
                    )
                  }
                </div>
                <div
                  data-scroll-id="BusinessTitle"
                  className={lessModule.coverBg}
                >
                  <BusinessTitle />
                </div>
              </div>
              <div
                // data-scroll
                // data-scroll-section
                className={lessModule.coverBg}
                data-scroll-id="BusinessImg"
                ref={imgWrapRef}
                style={{
                  overflow: isMobile() ? 'visible' : 'hidden',
                }}
              >
                {
                  isMobile() ? (
                    <BusinessImgMobile />
                  ) : (
                    <BusinessImg />
                  )
                }
              </div>
              <div
                // data-scroll
                // data-scroll-section
                data-scroll-id="Tech"
              >
                {
                  isMobile() ? (
                    <TechMobile />
                  ) : (
                    <Tech />
                  )
                }
              </div>
              <div
                // data-scroll
                // data-scroll-section
                data-scroll-id="SocialValue"
              >
                <SocialValue />
              </div>
            </div>
            <div
              data-scroll
              data-scroll-section
              data-scroll-delay="1500"
              data-scroll-id="Footer"
            >
              <Footer />
            </div>
          </>
        )
      }
    </div>
  )
}
