// import banner1 from '@/asset/banner/01新消费-1.jpg'
// import banner2 from '@/asset/banner/01新消费-2.jpg'
// import banner3 from '@/asset/banner/02教育科技-1.jpg'
// import banner4 from '@/asset/banner/02教育科技-2.jpg'
// import banner5 from '@/asset/banner/03数字出版.jpg'

import { isMobile } from '@/utils/common'

export const bannerImgs = isMobile() ? {
  banner1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/V57Xs86ANfRwIAWqycQqO.png',
  banner2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/9NZBn5TZNII6lR4483Ql8.png',
  banner3: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/KTCMSXGGL3mAeIN91rGe.png',
  banner4: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/YftmQcyCf5EbI9WwFbAr5.png',
  banner5: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/VjPrFiQBngVRPSS1LdY7.png',
} : {
  banner1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/qHhVzBX1ItDBnqnt8WN7R.jpeg',
  banner2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/q9uJBCOWK2gWugOLfLEyn.png',
  banner3: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/qwgtY7ZPiXF3uqEMJNJEdA.jpg',
  banner4: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/zSJ86KhyJdOcNFeX5K4EVC.jpg',
  banner5: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/oHwjKQApsrPqmmlnYbDS2.jpeg',
}
