import { socialValueText } from '@/constants/texts'
import { throttle } from 'lodash-es'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { getElementMobileSize, getElementSize, getElementTranslateX, getHomeProperSize, isMobile } from '@/utils/common'
import Draggable from 'react-draggable'
import { ReactComponent as IconArrowRight } from '@/asset/mobile/social/arrow-right.svg'
import { useTouchX } from '@/hooks/common'
import ContentTitle from '../content-title'
import lessModule from './index.module.less'

const socialValueTextLength = socialValueText.length

let mouseDownTime = 0

export default function SocialValue() {
  const [cardWidth, setCardWidth] = useState('575px')
  const [cardGag, setCardGap] = useState('22px')

  const [dragX, setDragX] = useState(0)

  const timelineRef = useRef<gsap.core.Timeline>()
  const clearAnimate = useRef(() => {})
  const wrapRef = useRef<HTMLDivElement>(null)
  const dragWrapRef = useRef<HTMLDivElement>(null)
  const cardListRef = useRef<HTMLDivElement>(null)

  const { touchBind } = useTouchX()

  const onCardMouseDown = useCallback(() => {
    mouseDownTime = +new Date()
  }, [])

  const onCardMouseUp = useCallback((link: string) => {
    const now = +new Date()
    if (now - mouseDownTime < 100) {
      window.open(link, '_blank')
    }
  }, [])

  const getDragX = useCallback(() => {
    if (cardListRef.current) {
      return getElementTranslateX(cardListRef.current)
    }
    return 0
  }, [])

  const animateCard = useCallback(() => {
    const t = gsap.timeline()
    timelineRef.current = t
    if (cardListRef.current) {
      const curX = getDragX()
      const AnimateDistance = (parseInt(cardWidth) + parseInt(cardGag)) * 2 * socialValueTextLength
      t.fromTo(
        `.${lessModule.cardList}`,
        {
          transform: `translate3d(${curX}px, 0px, 0px)`,
        },
        {
          transform: `translate3d(${AnimateDistance * -1}px, 0px, 0px)`,
          duration: 60 * (AnimateDistance + curX) / AnimateDistance,
          ease: 'none',
          repeat: 0,
          onComplete() {
            t.fromTo(
              `.${lessModule.cardList}`,
              {
                transform: 'translate3d(0px, 0px, 0px)',
              },
              {
                transform: `translate3d(${AnimateDistance * -1}px, 0px, 0px)`,
                duration: 60,
                ease: 'none',
                repeat: -1,
              },
            )
          },
        },
      )
    }
    clearAnimate.current = () => {
      t.kill()
      timelineRef.current = undefined
    }
    return clearAnimate.current
  }, [cardGag, cardWidth, getDragX])

  const onDragStart = useCallback(() => {
    const oneRound = (parseInt(cardWidth) + parseInt(cardGag)) * socialValueTextLength
    let x = getDragX()
    if (Math.abs(x) > oneRound) {
      x += oneRound
    }
    setDragX(x)
    clearAnimate.current()
  }, [cardGag, cardWidth, getDragX])

  const onDrag = useCallback((e: any, data: any) => {
    setDragX(data.x)
  }, [])

  const onDragStop = useCallback(() => {
    setDragX(getDragX())
    animateCard()
  }, [animateCard, getDragX])

  const onMouseOver = useCallback(() => {
    timelineRef.current && timelineRef.current.pause()
  }, [])

  const onMouseOut = useCallback(() => {
    timelineRef.current && timelineRef.current.resume()
  }, [])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      if (isMobile()) {
        setCardWidth(getElementMobileSize(316))
        setCardGap(getElementMobileSize(14))
      } else {
        setCardWidth(getElementSize(575))
        setCardGap(getElementSize(22))
      }
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    let clear = () => {}

    const observe = () => {
      if (!wrapRef.current) {
        setTimeout(() => {
          observe()
        }, 100)
      } else {
        if (!window.IntersectionObserver) {
          clearAnimate.current = animateCard()
          return
        }
        // 创建观察器
        const myObserver = new IntersectionObserver((entries) => {
          entries.forEach((en) => {
            // console.log(en.isIntersecting, en.intersectionRatio)
            if (en.isIntersecting || en.intersectionRatio > 0) {
              clearAnimate.current = animateCard()
            } else {
              timelineRef.current && timelineRef.current.pause()
            }
          })
        })
        // 获取目标元素
        const target = wrapRef.current
        // 开始监听目标元素
        myObserver.observe(target)
        clear = () => {
          myObserver.unobserve(target)
        }
      }
    }

    observe()

    return () => {
      clearAnimate.current()
      clear()
    }
  }, [animateCard, cardWidth, getDragX])

  useEffect(() => {
    let clear = () => {}
    const element = dragWrapRef.current
    if (element) {
      clear = touchBind(element)
    }
    return () => {
      clear()
    }
  }, [touchBind])

  return (
    <div
      className={`${lessModule.social} ${isMobile() ? lessModule.mobile : ''}`}
      ref={wrapRef}
      data-scroll
      data-scroll-offset="-600,0"
      data-scroll-call={`preloadImg, socialImg, ${lessModule.social}`}
    >
      <div
        className={lessModule.contentWrap}
      >
        <ContentTitle
          type="socialValue"
          style={{
            marginBottom: isMobile() ? '.25rem' : '.35rem',
            marginTop: isMobile() ? 0 : '.98rem',
            marginLeft: isMobile() ? 0 : undefined,
          }}
          mobile={isMobile()}
        />
        <div
          className={lessModule.bigText}
        >
          <div
            className={lessModule.line}
          >以科技践行社会责任</div>
          <div
            className={lessModule.line}
          >创造更多社会价值</div>
        </div>
      </div>

      <div
        className={lessModule.cardWrap}
        ref={dragWrapRef}
      >
        <Draggable
          axis="x"
          bounds={{
            left: ((parseInt(cardWidth) + parseInt(cardGag)) * 2 * socialValueText.length - parseInt(cardGag) - getHomeProperSize().winWidth) * -1,
            right: 0,
          }}
          position={{
            x: dragX,
            y: 0,
          }}
          onStart={onDragStart}
          onDrag={onDrag}
          onStop={onDragStop}
        >
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div
            className={lessModule.cardList}
            onMouseOver={!isMobile() ? onMouseOver : undefined}
            onMouseOut={!isMobile() ? onMouseOut : undefined}
            ref={cardListRef}
          >
            {
              [...socialValueText, ...socialValueText, ...socialValueText].map((item, index) => (
                <div
                  className={lessModule.card}
                  key={index}
                  onMouseDown={!isMobile() ? onCardMouseDown : undefined}
                  onMouseUp={!isMobile() ? () => onCardMouseUp(item.link) : undefined}
                  onTouchStart={isMobile() ? onCardMouseDown : undefined}
                  onTouchEnd={isMobile() ? () => onCardMouseUp(item.link) : undefined}
                  style={{
                    width: cardWidth,
                    marginRight: cardGag,
                  }}
                >
                  <div className={lessModule.cardTop}>
                    <div className={lessModule.left}>
                      <img
                        data-src={item.img}
                        className={`${lessModule.img} lazy`}
                        alt=""
                      />
                      <div
                        className={lessModule.dateAndLocatioin}
                      >
                        <div className={lessModule.date}>{item.date}</div>
                        <div className={lessModule.location}>{item.location}</div>
                      </div>
                    </div>
                    <div
                      className={lessModule.right}
                    >{item.number}</div>
                  </div>
                  <div
                    className={lessModule.text}
                  >
                    <div className={lessModule.line}>{item.line1}</div>
                    <div className={lessModule.line}>{item.line2}</div>
                    <div className={lessModule.line}>{item.line3}</div>
                  </div>
                  {
                    isMobile() && (
                      <div className={lessModule.arrow}>
                        <IconArrowRight />
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
        </Draggable>
      </div>
    </div>
  )
}
