import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as LogoImg } from '@/asset/logo.svg'
import { bannerText } from '@/constants/texts'
import gsap from 'gsap'
import { ReactComponent as ToggleIcon } from '@/asset/icons/banner-toggle.svg'
import { getHomeProperSize, isMobile } from '@/utils/common'
import { throttle } from 'lodash-es'
import { LocomotiveScrollEvent } from '@/pages/home'
import { useTouchX } from '@/hooks/common'
import lessModule from './index.module.less'
import BannerCircle from './circle'

const bannerTextLength = bannerText.length

const circleDuration = 1.5
const numberDuration = 0.5
const textDuration = 0.5
const imgScaleDuration = 2
const imgOpacityDuration = 1

const defaultTouchEnd = -99999999

export default function Banner() {
  const circleRef = React.createRef<HTMLDivElement>()
  // banner 数组的索引
  const arrIndex = useRef(0)
  // 当前展示的 banner 索引
  const bannerIndex = useRef(0)
  // 底部 banner 索引
  const bannerUnderIndex = useRef(1)
  // 01、02 等 banner 数字
  const numberTextArrIndex = useRef(0)
  // 01、02 等 banner 底部数字
  const numberTextUnderArrIndex = useRef(1)
  // 中文文字索引
  const cnTextIndex = useRef(0)
  const circleRotate = useRef(0)
  // banner src
  const bannerSrc = useRef(bannerText[0][0].bg)
  // 底部 banner src
  const bannerUnderSrc = useRef(bannerText[0][1].bg)

  const [, forceUpdate] = useState({})
  // 是否可以切换下一张图片
  const canChangeIndex = useRef(false)
  const timelineArr = useRef<gsap.core.Timeline[]>([])

  const wrapRef = useRef<HTMLDivElement>(null)
  const progressBarRef = useRef<HTMLDivElement>(null)

  const [btnVisible, setBtnVisible] = useState(false)
  const [btnStyle, setBtnStyle] = useState<CSSProperties>({})

  const [circleSize, setCircleSize] = useState('40vw')
  // const [numberFontSize, setNumberFontSize] = useState('138px')
  // const [cnFontSize, setCnFontSize] = useState('62px')
  // const [enFontSize, setEnFontSize] = useState('16px')
  const [bannerUnderOpacity, setBannerUnderOpacity] = useState(0)

  const [progressBarWidth, setProgressBarWidth] = useState(1 / bannerTextLength * 100)

  const touchStartPos = useRef(0)
  const touchEndPos = useRef(defaultTouchEnd)
  const touchStartTime = useRef(0)

  const { touchBind } = useTouchX()

  const getNextArrIndex = useCallback(() => (arrIndex.current + 1) % bannerTextLength, [])

  const getNextBannerIndex = useCallback(() => (bannerIndex.current + 1) % bannerText[arrIndex.current].length, [])
  const getNextBannerUnderIndex = useCallback(() => (bannerUnderIndex.current + 1) % bannerText[arrIndex.current].length, [])
  const getNextBannerSrc = useCallback((manual?: boolean) => {
    if (manual) {
      return bannerText[arrIndex.current][0].bg
    }
    return bannerText[arrIndex.current][bannerUnderIndex.current].bg
  }, [])
  const getNextBannerUnderSrc = useCallback((manual?: boolean) => {
    if (manual) {
      // 底部的 banner 更新时 arrIndex 还没更新，所以这里要调用 getNextArrIndex 获取下一个
      return bannerText[getNextArrIndex()][0].bg
    }
    return bannerText[arrIndex.current][bannerUnderIndex.current]?.bg
  }, [getNextArrIndex])

  const getNextNumberUnderIndex = useCallback(() => (numberTextArrIndex.current + 1) % bannerTextLength, [])

  const updateProgressBarWidth = useCallback(() => {
    const result = (circleRotate.current + 1) % bannerTextLength
    if (result === 0) {
      setProgressBarWidth(100)
      return
    }
    if (result === 1) {
      setProgressBarWidth(0)
      if (progressBarRef.current) {
        progressBarRef.current.style.transition = 'none'
      }
      setTimeout(() => {
        if (progressBarRef.current) {
          progressBarRef.current.style.transition = `${textDuration * 2}s`
        }
        setProgressBarWidth(1 / bannerTextLength * 100)
      }, 100)
      return
    }
    setProgressBarWidth(result / bannerTextLength * 100)
  }, [])

  // 中间的圆弧和文字的动画
  const circleAndTextAnimate = useCallback(() => {
    // 圆弧每次旋转120度
    const timeLineCircle = gsap.timeline({ ease: 'none' })
    if (!isMobile()) {
      timeLineCircle.to(
        `.${lessModule.bannerCircle}`,
        {
          rotateZ: circleRotate.current * 120,
          duration: circleDuration,
        },
      )
    }

    // 数字底图，透明从0到1
    const timeLineUnderNumber = gsap.timeline({ ease: 'none' })

    timeLineUnderNumber.to(
      `.${lessModule.numberUnder}`,
      {
        opacity: 1,
        duration: numberDuration,
        onComplete() {

        },
      },
    )

    // 数字动画，透明度从1到0
    const timeLineNumber = gsap.timeline({ ease: 'none' })
    timeLineNumber.to(
      `.${lessModule.number}`,
      {
        opacity: 0,
        duration: numberDuration - 0.2,
        onComplete() {
          // 上一个数字透明度从1到0之后，切换到下一个数字
          numberTextArrIndex.current = getNextArrIndex()
          forceUpdate({})
          // 将下一个数字透明度从0到1展示
          timeLineNumber.to(
            `.${lessModule.number}`,
            {
              opacity: 1,
              duration: numberDuration,
              onComplete() {
                timeLineUnderNumber.to(
                  `.${lessModule.numberUnder}`,
                  {
                    opacity: 0,
                    duration: 0,
                    onComplete() {
                      // 下一个数字透明度从0到1展示完成后，数字底图切换到下一个数字
                      numberTextUnderArrIndex.current = getNextNumberUnderIndex()
                      forceUpdate({})
                    },
                  },
                )
              },
            },
          )
        },
      },
    )

    // 文字透明度从1到0，然后切换到下一个文字，接着透明度从0到1
    const timeLineText = gsap.timeline({ ease: 'none' })
    timeLineText.to(
      `.${lessModule.textWrap}`,
      {
        opacity: 0,
        duration: textDuration,
        onComplete() {
          cnTextIndex.current = getNextArrIndex()
          forceUpdate({})
          timeLineText.to(
            `.${lessModule.textWrap}`,
            {
              opacity: 1,
              duration: textDuration,
            },
          )
        },
      },
    )
    timelineArr.current.push(...[timeLineCircle, timeLineUnderNumber, timeLineNumber, timeLineText])
  }, [getNextArrIndex, getNextNumberUnderIndex])

  // 进入动画
  const doEnterAnimation = useCallback(() => {
    const t = gsap.timeline({ ease: 'none' })
    // banner图片缩放变为1，透明度变为1，立即生效
    t.to(
      `.${lessModule.bannerImg}`,
      {
        scale: 1,
        duration: 0.1,
      },
    ).to(
      `.${lessModule.bannerImg}`,
      {
        opacity: 1,
        duration: 0.1,
      },
    )
    timelineArr.current.push(t)
    return t
  }, [])

  // 离开动画
  const doLeaveAnimation = useCallback((isToNextArr: boolean, manual?: boolean) => {
    const t = gsap.timeline({ ease: 'none' })
    // 中间的圆相关的动画，在移动端下，也控制着进度条动画
    const animateCircleAndText = async () => {
      circleRotate.current += 1
      circleAndTextAnimate()
      // 更新进度条
      updateProgressBarWidth()
    }
    // 不是手动点击下一页，则需要banner图片scale动画
    if (!manual) {
      canChangeIndex.current = true
      t.fromTo(
        `.${lessModule.bannerImg}`,
        {
          scale: 1,
        },
        {
          scale: 1.1,
          duration: imgScaleDuration,
          ease: 'none',
          onComplete() {
            canChangeIndex.current = false
            if (isToNextArr) {
              animateCircleAndText()
            }
          },
        },
      )
    } else {
      // 手动点击下一页，直接进行中间的圆的动画，不用banner的scale动画
      animateCircleAndText()
    }
    // banner图片透明度从1到0
    t.to(
      `.${lessModule.bannerImg}`,
      {
        opacity: 0,
        scale: 1.125,
        ease: 'none',
        duration: imgOpacityDuration,
      },
    )
    // banner图片透明度从1到0
    timelineArr.current.push(t)
    return t
  }, [circleAndTextAnimate, updateProgressBarWidth])

  const onNextBanner = useCallback(async (manual?: boolean) => {
    if (manual && !canChangeIndex.current) {
      return
    }
    const isToNextArr = manual || (bannerIndex.current + 1 === bannerText[arrIndex.current].length)
    // 只有在进行banner图片scale动画中间可以进行下一页的切换
    if (isToNextArr) {
      canChangeIndex.current = false
    }
    bannerUnderSrc.current = getNextBannerUnderSrc(isToNextArr)
    forceUpdate({})
    // 进行下一个动画之前先清空上一个动画
    timelineArr.current.forEach((timeline) => {
      timeline.pause()
      timeline.kill()
    })
    timelineArr.current = []

    // 执行离开动画
    await doLeaveAnimation(isToNextArr, manual)
    if (isToNextArr) {
      arrIndex.current = getNextArrIndex()
    }
    if (isToNextArr) {
      bannerIndex.current = 0
    } else {
      bannerIndex.current = getNextBannerIndex()
    }
    bannerSrc.current = getNextBannerSrc(isToNextArr)
    forceUpdate({})
    // 执行进入动画
    await doEnterAnimation()
    if (isToNextArr) {
      bannerUnderIndex.current = 1
    } else {
      bannerUnderIndex.current = getNextBannerUnderIndex()
    }

    forceUpdate({})
    canChangeIndex.current = true
    onNextBanner()
  }, [getNextBannerUnderSrc, doLeaveAnimation, getNextBannerSrc, doEnterAnimation, getNextArrIndex, getNextBannerIndex, getNextBannerUnderIndex])

  const onTouchStart = useCallback((e: TouchEvent) => {
    if (isMobile()) {
      touchStartPos.current = e.touches[0].pageX
      touchStartTime.current = +new Date()
    }
  }, [])

  const onTouchMove = useCallback((e: TouchEvent) => {
    if (isMobile()) {
      touchEndPos.current = e.touches[0].pageX
    }
  }, [])

  const onTouchEnd = useCallback((e: TouchEvent) => {
    if (isMobile()) {
      const endPos = touchEndPos.current
      const endTime = +new Date()
      if (endTime - touchStartTime.current < 400) {
        const diff = endPos - touchStartPos.current
        if (diff < -50 && endPos !== defaultTouchEnd) {
          onNextBanner(true)
        }
      }
      touchEndPos.current = defaultTouchEnd
    }
  }, [onNextBanner])

  useEffect(() => {
    onNextBanner()
  }, [onNextBanner])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      const { winWidth, winHeight } = getHomeProperSize()
      setCircleSize(`${Math.min(0.4 * winWidth, 0.71 * winHeight)}px`)
      // setNumberFontSize(`${Math.min(0.1078 * winWidth, 0.1916 * winHeight)}px`)
      // setCnFontSize(`${Math.min(0.048 * winWidth, 0.086 * winHeight)}px`)
      // setEnFontSize(`${Math.min(0.0125 * winWidth, 0.022 * winHeight)}px`)
    }, 200, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    let clear = () => {}

    const observe = () => {
      if (!wrapRef.current) {
        setTimeout(() => {
          observe()
        }, 100)
      } else {
        if (!window.IntersectionObserver) {
          window.removeEventListener('mousemove', onMouseMove, false)
          timelineArr.current.forEach((t) => {
            if (!t.paused()) {
              t.pause()
            }
          })
          return
        }
        // 创建观察器
        const myObserver = new IntersectionObserver((entries) => {
          entries.forEach((en) => {
            // console.log(en.isIntersecting, en.intersectionRatio)
            if (en.isIntersecting || en.intersectionRatio > 0) {
              if (!isMobile()) {
                window.addEventListener('mousemove', onMouseMove, false)
              }
              timelineArr.current.forEach((t) => {
                if (t.paused()) {
                  t.resume()
                }
              })
            } else {
              window.removeEventListener('mousemove', onMouseMove, false)
              timelineArr.current.forEach((t) => {
                if (!t.paused()) {
                  t.pause()
                }
              })
            }
          })
        })
        // 获取目标元素
        const target = wrapRef.current
        // 开始监听目标元素
        myObserver.observe(target)
        clear = () => {
          myObserver.unobserve(target)
          window.removeEventListener('mousemove', onMouseMove, false)
        }
      }
    }

    observe()

    // 设置下一页按钮的位置
    const onMouseMove = throttle((e: MouseEvent) => {
      const { winWidth, winHeight } = getHomeProperSize()
      const { value: scrollEvent } = LocomotiveScrollEvent
      if (scrollEvent) {
        const { scroll: { y } } = scrollEvent
        const btnTop = y + e.pageY
        setBtnVisible(btnTop <= winHeight - 200 && e.pageX > 100 && e.pageX < winWidth - 100 && e.pageY > 100 && e.pageY < winHeight - 100)
        if (btnTop <= winHeight) {
          setBtnStyle({
            transform: `translate3d(calc(-50% + ${e.pageX}px), calc(-50% + ${btnTop}px), 0)`,
            // transform: `translate3d(${e.pageX}px, ${e.pageY}px, 0)`,
            transition: '0.15s',
            // position: 'fixed',
          })
        }
      }
    }, 100)
    return () => {
      window.removeEventListener('mousemove', onMouseMove, false)
      clear()
    }
  }, [])

  useEffect(() => {
    let clear = () => {}
    const element = wrapRef.current
    if (element && isMobile()) {
      element.addEventListener('touchstart', onTouchStart)
      element.addEventListener('touchmove', onTouchMove)
      element.addEventListener('touchend', onTouchEnd)
      clear = touchBind(element)
    }
    return () => {
      clear()
      element?.addEventListener('touchstart', onTouchStart)
      element?.addEventListener('touchmove', onTouchMove)
      element?.addEventListener('touchend', onTouchEnd)
    }
  }, [onTouchEnd, onTouchMove, onTouchStart, touchBind])

  return (
    <div
      className={`${lessModule.banner} ${isMobile() ? lessModule.mobile : ''}`}
      ref={wrapRef}
    >
      <div className={lessModule.logo}>
        <LogoImg />
      </div>
      <div>
        <img
          src={bannerSrc.current}
          className={lessModule.bannerImg}
          alt=""
          onLoad={() => {
            setBannerUnderOpacity(1)
          }}
        />
        <img
          src={bannerUnderSrc.current || bannerSrc.current}
          className={lessModule.bannerImgUnder}
          alt=""
          style={{
            opacity: bannerUnderOpacity,
          }}
        />
        <div
          className={lessModule.circle}
          style={{
            width: circleSize,
            height: circleSize,
          }}
        >
          {
            !isMobile() && (
              <BannerCircle className={lessModule.bannerCircle} ref={circleRef} />
            )
          }
          <div
            className={lessModule.number}
            // style={{
            //   fontSize: numberFontSize,
            //   marginLeft: `-${parseInt(numberFontSize) * 0.058}px`,
            //   lineHeight: `${parseInt(numberFontSize) * 1.173}px`,
            // }}
          >{bannerText[numberTextArrIndex.current][0].number}</div>
          <div
            className={lessModule.numberUnder}
            // style={{
            //   fontSize: numberFontSize,
            //   marginLeft: `-${parseInt(numberFontSize) * 0.058}px`,
            //   lineHeight: `${parseInt(numberFontSize) * 1.173}px`,
            // }}
          >{bannerText[numberTextUnderArrIndex.current][0].number}</div>
          <div className={lessModule.textWrap}>
            <div
              className={lessModule.cn1}
              // style={{
              //   fontSize: cnFontSize,
              //   lineHeight: `${parseInt(cnFontSize) * 1.21}px`,
              // }}
            >{bannerText[cnTextIndex.current][0].cn1}</div>
            <div
              className={lessModule.cn2}
              // style={{
              //   fontSize: cnFontSize,
              //   lineHeight: `${parseInt(cnFontSize) * 1.21}px`,
              // }}
            >{bannerText[cnTextIndex.current][0].cn2}</div>
            <div
              className={lessModule.en}
              // style={{
              //   fontSize: enFontSize,
              //   lineHeight: `${parseInt(enFontSize) * 1.1875}px`,
              // }}
            >{bannerText[cnTextIndex.current][0].en}</div>
          </div>
        </div>
        {
          isMobile() && (
            <div className={lessModule.progressWrap}>
              <div
                className={lessModule.progress}
                ref={progressBarRef}
                style={{
                  width: `${progressBarWidth}%`,
                  transition: `${textDuration * 2}s`,
                }}
              />
            </div>
          )
        }
        {
          !isMobile() && (
            <div
              className={lessModule.toggleIcon}
              onClick={() => onNextBanner(true)}
              style={{
                ...btnStyle,
                display: btnVisible ? 'block' : 'none',
              }}
            >
              <ToggleIcon />
            </div>
          )
        }
      </div>
    </div>
  )
}
