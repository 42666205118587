import React, { CSSProperties, useMemo } from 'react'
import { ReactComponent as ImgTech } from '@/asset/content-title/看云科技.svg'
import { ReactComponent as ImgExp } from '@/asset/content-title/看云历程.svg'
import { ReactComponent as ImgBusiness } from '@/asset/content-title/看云业务.svg'
import { ReactComponent as ImgNews } from '@/asset/content-title/看云资讯.svg'
import { ReactComponent as ImgSocial } from '@/asset/content-title/社会价值.svg'

import { ReactComponent as ImgNewsMobile } from '@/asset/mobile/content-title/news.svg'
import { ReactComponent as ImgBusinessMobile } from '@/asset/mobile/content-title/business.svg'
import { ReactComponent as ImgExperienceMobile } from '@/asset/mobile/content-title/experience.svg'
import { ReactComponent as ImgSocialMobile } from '@/asset/mobile/content-title/social.svg'
import { ReactComponent as ImgTechMobile } from '@/asset/mobile/content-title/tech.svg'

import lessModule from './index.module.less'

const imgMap = {
  tech: ImgTech,
  experience: ImgExp,
  business: ImgBusiness,
  news: ImgNews,
  socialValue: ImgSocial,
}

const mobileImgMap = {
  tech: ImgTechMobile,
  experience: ImgExperienceMobile,
  business: ImgBusinessMobile,
  news: ImgNewsMobile,
  socialValue: ImgSocialMobile,
}

export default function ContentTitle({
  style = {},
  type,
  mobile,
}: {
  style?: CSSProperties,
  type: 'tech' | 'experience' | 'business' | 'news' | 'socialValue',
  mobile?: boolean,
}) {
  const ImgComponent = useMemo(() => {
    if (mobile) {
      return mobileImgMap[type]
    }
    return imgMap[type]
  }, [mobile, type])

  return (
    <div
      className={`${lessModule.title} ${mobile ? lessModule.mobile : ''}`}
      style={{
        ...style,
      }}
    >
      <ImgComponent />
    </div>
  )
}
