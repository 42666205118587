import { newsText as originNewsText } from '@/constants/texts'
import gsap from 'gsap'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { throttle } from 'lodash-es'
import { getElementSize, getHomeProperSize } from '@/utils/common'
import lessModule from './index.module.less'
import ContentTitle from '../content-title'

const newsText = [...originNewsText, ...originNewsText].map((item, index) => {
  const temp = { ...item }
  temp.id = index
  return temp
})

const changeDuration = 0.3

const newsTextLength = newsText.length

const imgScale = 0.553

export default function News() {
  const disabledAnimate = useRef(false)
  const newsIndex = useRef(0)
  const textIndex = useRef(0)
  const [leftMarginRight, setLeftMarginRight] = useState('37px')
  const [bigImgWidth, setBigImgWidth] = useState('414px')
  const [imgGap, setImgGap] = useState('22px')

  const smallImgSize = useMemo(() => parseInt(bigImgWidth) * imgScale, [bigImgWidth])

  const timelinsRef = useRef<gsap.core.Timeline[]>([])

  const [, forceUpdate] = useState({})

  const getCurNewsIndex = useCallback(() => {
    let index = newsIndex.current % newsTextLength
    if (index < 0) {
      index += newsTextLength
    }
    return index
  }, [])

  const getTextIndex = useCallback(() => {
    let index = textIndex.current % newsTextLength
    if (index < 0) {
      index += newsTextLength
    }
    return index
  }, [])

  const getShowingTextArr = useCallback(() => {
    const curIndex = getCurNewsIndex()
    const frontArr = newsText.slice(0, curIndex)
    const endArr = newsText.slice(curIndex)
    const resultArr = [...endArr, ...frontArr]
    resultArr.unshift(resultArr.pop() || newsText[0])
    return resultArr
  }, [getCurNewsIndex])

  const getImgTranslateX = useCallback((index: number) => {
    let x = 0
    if (index === 0) {
      x = (smallImgSize + parseInt(imgGap)) * -1
    } else if (index > 1) {
      x = parseInt(bigImgWidth) + parseInt(imgGap) + (smallImgSize + parseInt(imgGap)) * (index - 2 > 0 ? index - 2 : 0)
    }
    return x
  }, [bigImgWidth, imgGap, smallImgSize])

  const animateImgs = useCallback(async (type: 'next' | 'pre' | 'refresh') => {
    if (disabledAnimate.current && type !== 'refresh') {
      return []
    }
    if (type !== 'refresh') {
      disabledAnimate.current = true
    }
    if (type === 'next') {
      newsIndex.current += 1
    } else if (type === 'pre') {
      newsIndex.current -= 1
    }

    const resultArr = getShowingTextArr()
    // 图片
    const timelines = resultArr.map((item, index) => {
      const x = getImgTranslateX(index)

      const t = gsap.timeline()

      if ((type === 'next' && index === resultArr.length - 1) || (type === 'pre' && index === 0)) {
        t.to(
          `.${lessModule.img}-${item.id}`,
          {
            opacity: 0,
            duration: 0,
          },
        )
      }

      t.to(
        `.${lessModule.img}-${item.id}`,
        {
          transform: `translate3d(${x}px, 0, 0) scale(${index === 1 ? 1 : imgScale})`,
          duration: type === 'refresh' ? 0 : changeDuration,
        },
      )
      t.to(
        `.${lessModule.img}-${item.id}`,
        {
          opacity: 1,
          duration: 0,
        },
      )

      return t
    })
    // 左边的大文字
    const timelineBigText = gsap.timeline()
    timelineBigText.to(
      `.${lessModule.textWrap}`,
      {
        opacity: type === 'refresh' ? 1 : 0,
        duration: type === 'refresh' ? 0 : changeDuration / 3 * 2,
        onComplete() {
          if (type === 'next') {
            textIndex.current += 1
          } else if (type === 'pre') {
            textIndex.current -= 1
          }
          forceUpdate({})
        },
      },
    )
    timelineBigText.to(
      `.${lessModule.textWrap}`,
      {
        opacity: 1,
        duration: type === 'refresh' ? 0 : changeDuration / 3,
      },
    )

    // 右边的文字
    const timelineDateText = gsap.timeline()
    timelineDateText.to(
      `.${lessModule.dateText}`,
      {
        opacity: type === 'refresh' ? 1 : 0,
        duration: type === 'refresh' ? 0 : changeDuration / 3 * 2,
        onComplete() {

        },
      },
    )
    timelineDateText.to(
      `.${lessModule.dateText}`,
      {
        opacity: 1,
        duration: type === 'refresh' ? 0 : changeDuration / 3,
      },
    )

    timelinsRef.current.push(...[...timelines, timelineBigText, timelineDateText])
    await Promise.all(timelines)
    if (type !== 'refresh') {
      disabledAnimate.current = false
    }
    timelinsRef.current = []
    return timelines
  }, [getImgTranslateX, getShowingTextArr])

  const onPreImg = useCallback(() => {
    animateImgs('pre')
    forceUpdate({})
  }, [animateImgs])

  const onNextImg = useCallback(() => {
    animateImgs('next')
    forceUpdate({})
  }, [animateImgs])

  const onClickImg = useCallback(async (index: number) => {
    // const cur = getCurNewsIndex()
    window.open(newsText[index].link, '_blank')
    // if (index === cur) {
    //   window.open(newsText[index].link, '_blank')
    //   return
    // }
    // const moveCount = Math.min(Math.abs(index - cur), index + newsTextLength - cur)
    // for (let i = 0; i < moveCount; i++) {
    //   // eslint-disable-next-line no-await-in-loop
    //   await animateImgs('next')
    //   forceUpdate({})
    // }
  }, [])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      const { winWidth } = getHomeProperSize()
      let leftMarginR = getElementSize(37)
      if (winWidth >= 1440) {
        leftMarginR = `${63 / 1440 * winWidth}px`
      }
      if (winWidth >= 1920) {
        leftMarginR = `${107 / 1920 * winWidth}px`
      }
      setLeftMarginRight(leftMarginR)
      setBigImgWidth(getElementSize(415))
      setImgGap(getElementSize(22))
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    animateImgs('refresh')
  }, [animateImgs])

  return (
    <div className={lessModule.news}>
      <div
        className={lessModule.newContent}
      >
        <div
          className={lessModule.left}
          style={{
            marginRight: leftMarginRight,
            width: `${parseInt(bigImgWidth) * 0.7826}px`,
          }}
        >
          <ContentTitle
            type="news"
          />
          <div className={lessModule.textWrap}>
            <div
              className={lessModule.bigText}
            >
              <div
                className={lessModule.bigTextLine}
              >{newsText[getTextIndex()].line1}</div>
              <div
                className={lessModule.bigTextLine}
              >{newsText[getTextIndex()].line2}</div>
            </div>
            <div className={lessModule.smallText}>
              <div
                className={lessModule.smallTextLine}
              >{newsText[getTextIndex()].line3}</div>
              <div
                className={lessModule.smallTextLine}
              >{newsText[getTextIndex()].line4}</div>
              <div
                className={lessModule.smallTextLine}
              >{newsText[getTextIndex()].line5}</div>
              <div
                className={lessModule.smallTextLine}
              >{newsText[getTextIndex()].line6}</div>
            </div>
          </div>
          <div
            className={lessModule.btn}
          >
            <div
              className={`${lessModule.btnItem} ${lessModule.btnItemLeft}`}
              onClick={onPreImg}
            >
              {/* <IconToggleLeft /> */}
            </div>
            <div
              className={`${lessModule.btnItem} ${lessModule.btnItemRight}`}
              onClick={onNextImg}
            >
              {/* <IconToggleRight /> */}
            </div>
          </div>
        </div>
        <div
          className={lessModule.right}
          style={{
            height: `${parseInt(bigImgWidth) * 1.05783}px`,
            // height: bigImgWidth,
          }}
        >
          <div className={lessModule.imgWrap}>
            {newsText.map((item, index) => (
              <div
                className={`${lessModule.imgItem} ${lessModule.img}-${item.id}`}
                style={{
                  width: bigImgWidth,
                }}
                key={item.id}
              >
                <img
                  className={`${lessModule.img}`}
                  style={{
                    width: bigImgWidth,
                  }}
                  src={item.img}
                  alt=""
                  onClick={() => {
                    onClickImg(index)
                  }}
                />
              </div>
            ))}
          </div>
          <div
            className={lessModule.dateText}
            style={{
              left: `${parseInt(bigImgWidth) + parseInt(imgGap)}px`,
            }}
          >
            <div
              className={lessModule.month}
            >
              {newsText[getTextIndex()].date}
            </div>
            <div className={lessModule.brandAndYear}>
              <div
                className={lessModule.brand}
              >{newsText[getTextIndex()].brand}</div>
              <div
                className={lessModule.sep}
              />
              <div
                className={lessModule.brand}
              >{newsText[getTextIndex()].year}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
