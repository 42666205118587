import { experienceText } from '@/constants/texts'
import { throttle } from 'lodash-es'
import gsap from 'gsap'
import { ReactComponent as BtnLeftMobile } from '@/asset/mobile/experience/left-btn.svg'
import { ReactComponent as BtnRightMobile } from '@/asset/mobile/experience/right-btn.svg'
import { ReactComponent as ImgTimeAxis } from '@/asset/time-axis.svg'
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getElementMobileSize, getElementSize, isMobile } from '@/utils/common'
import { LocomotiveScrollEvent } from '@/pages/home'
import { ReactComponent as ImgTimeAxisMobile } from '@/asset/mobile/experience/timeline.svg'
import ContentTitle from '../content-title'
import lessModule from './index.module.less'

const duration = 0.5

const experienceTextLenght = experienceText.length

export default function Experience() {
  const [titleMarginTop, setTitleMarginTop] = useState('188px')
  const [bigYearFontSize, setBigYearFontSize] = useState('154px')
  const [smallYearFontSize, setSmallYearFontSize] = useState('66px')
  const [yearMoveGap, setYearMoveGap] = useState('240px')
  const [mouseOnYears, setMouseOnYears] = useState(false)
  const arrowDir = useRef<'left' | 'right'>('right')

  const [, forceUpdate] = useState({})
  const [btnVisible, setBtnVisible] = useState(false)

  const [btnStyle, setBtnStyle] = useState<CSSProperties>({})

  const dataSource = useRef([...experienceText])
  const descDataSource = useRef(experienceText[0])

  const extWrapRef = useRef<HTMLDivElement>(null)
  const wrapBoundingClient = useRef<DOMRect>()

  const disabledAnimating = useRef(false)
  // const autoTimer = useRef(0)
  const yearOffsetY = useMemo(() => `${parseInt(bigYearFontSize) * 0.1558}px`, [bigYearFontSize])
  const midIndex = useMemo(() => Math.floor(dataSource.current.length / 2), [])

  const onMouseEnterYear = useCallback(() => {
    setMouseOnYears(true)
  }, [])
  const onMouseLeaveYear = useCallback(() => {
    setMouseOnYears(false)
  }, [])

  const doAnimate = useCallback(async (type?: 'refresh' | 'next' | 'pre') => {
    // 年份动画
    const timelines = dataSource.current.map((item, index) => {
      const t = gsap.timeline()
      if (index === 0) {
        t.to(
          `.${lessModule.yearItem}-${item.id}`,
          {
            fontSize: parseInt(bigYearFontSize),
            color: '#1E2833',
            transform: 'translate3d(0, 0, 0)',
            duration: type === 'refresh' ? 0 : duration,
          },
        )
      } else if (index > midIndex) {
        t.to(
          `.${lessModule.yearItem}-${item.id}`,
          {
            fontSize: parseInt(smallYearFontSize),
            color: 'rgba(120,128,128,0.25)',
            transform: `translate3d(${(index - experienceTextLenght) * parseInt(yearMoveGap)}px, -${yearOffsetY}, 0)`,
            duration: type === 'refresh' ? 0 : duration,
          },
        )
      } else {
        t.to(
          `.${lessModule.yearItem}-${item.id}`,
          {
            fontSize: parseInt(smallYearFontSize),
            color: 'rgba(120,128,128,0.25)',
            transform: `translate3d(${(index) * parseInt(yearMoveGap)}px, -${yearOffsetY}, 0)`,
            duration: type === 'refresh' ? 0 : duration,
          },
        )
      }
      return t
    })
    // 描述信息动画
    const timelineDesc = gsap.timeline()
    timelineDesc.to(
      `.${lessModule.desc}`,
      {
        opacity: 0,
        duration: type === 'refresh' ? 0 : duration / 2,
        onComplete() {
          descDataSource.current = dataSource.current[0]
          forceUpdate({})
        },
      },
    )
    timelineDesc.to(
      `.${lessModule.desc}`,
      {
        opacity: 1,
        duration: type === 'refresh' ? 0 : duration / 2,
      },
    )
    await Promise.all([...timelines, timelineDesc])
    if (type !== 'refresh') {
      disabledAnimating.current = false
    }
    return timelines
  }, [bigYearFontSize, midIndex, smallYearFontSize, yearMoveGap, yearOffsetY])

  const animate = useCallback(async (type?: 'refresh' | 'next' | 'pre') => {
    if (type !== 'refresh' && disabledAnimating.current) {
      return []
    }
    if (type !== 'refresh') {
      disabledAnimating.current = true
      if (type === 'pre') {
        dataSource.current.unshift(dataSource.current.pop() || dataSource.current[0])
      }
      if (type === 'next') {
        dataSource.current.push(dataSource.current.shift() || dataSource.current[0])
      }
    }

    forceUpdate({})
    const result = await doAnimate(type)
    return result
  }, [doAnimate])

  const onClickYearItemFN = useCallback((index: number) => {
    if (index === 0) {
      return
    }
    if (index < midIndex) {
      for (let i = 0; i < index; i++) {
        disabledAnimating.current = true
        dataSource.current.push(dataSource.current.shift() || dataSource.current[0])
      }
    } else {
      for (let i = dataSource.current.length; i > index; i--) {
        disabledAnimating.current = true
        dataSource.current.unshift(dataSource.current.pop() || dataSource.current[0])
      }
    }
    forceUpdate({})
    doAnimate()
  }, [doAnimate, midIndex])

  const onClickYearItem = useMemo(() => throttle(onClickYearItemFN, duration * 1000), [onClickYearItemFN])

  // const autoAnimate = useCallback(() => {
  //   const auto = () => {
  //     animate()
  //   }
  //   autoTimer.current = window.setInterval(auto, 3000)
  // }, [animate])

  const onNextAnimate = useCallback(async () => {
    if (disabledAnimating.current) {
      return
    }
    // 手动切换下一个动画前，先取消自动播放
    // window.clearInterval(autoTimer.current)
    await animate(arrowDir.current === 'left' ? 'pre' : 'next')
    // autoAnimate()
  }, [animate])

  useEffect(() => {
    // 设置按钮位置
    const onMouseMove = throttle((e: MouseEvent) => {
      if (extWrapRef.current) {
        const btnTop = e.clientY
        const btnLeft = e.clientX
        const top = btnTop - (wrapBoundingClient.current?.top || 0)
        arrowDir.current = btnLeft > (wrapBoundingClient.current?.width || 0) / 2 ? 'right' : 'left'
        setBtnVisible(top > parseInt(titleMarginTop) && top < (wrapBoundingClient.current?.height || 1000) - 100 && btnLeft > 100 && btnLeft < (wrapBoundingClient.current?.width || 1000) - 100)
        setBtnStyle({
          transform: `translate3d(calc(-50% + ${btnLeft}px), calc(-50% + ${top}px), 0)`,
          transition: '0.15s',
        })
      }
    }, 100)

    let clear = () => {}

    const observe = () => {
      if (!extWrapRef.current) {
        setTimeout(() => {
          observe()
        }, 100)
      } else {
        if (!window.IntersectionObserver) {
          window.addEventListener('mousemove', onMouseMove, false)
          return
        }
        // 创建观察器
        const myObserver = new IntersectionObserver((entries) => {
          entries.forEach((en) => {
            if (en.isIntersecting || en.intersectionRatio > 0) {
              window.addEventListener('mousemove', onMouseMove, false)
            } else {
              setBtnVisible(false)
              window.removeEventListener('mousemove', onMouseMove, false)
            }
          })
        })
        // 获取目标元素
        const target = extWrapRef.current
        // 开始监听目标元素
        myObserver.observe(target)
        clear = () => {
          myObserver.unobserve(target)
        }
      }
    }

    const onScroll = () => {
      wrapBoundingClient.current = extWrapRef.current?.getBoundingClientRect()
    }

    if (!isMobile()) {
      observe()
      LocomotiveScrollEvent?.scroller?.on('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove, false)
      clear()
      // @ts-ignore
      LocomotiveScrollEvent?.scroller?.off('scroll', onScroll)
    }
  }, [titleMarginTop])

  useEffect(() => {
    animate('refresh')
    // autoAnimate()
    return () => {
      // window.clearInterval(autoTimer.current)
    }
  }, [animate])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      if (isMobile()) {
        setTitleMarginTop(getElementMobileSize(61))
        setBigYearFontSize(getElementMobileSize(85))
        setSmallYearFontSize(getElementMobileSize(31))
        setYearMoveGap(getElementMobileSize(138))
      } else {
        setTitleMarginTop(getElementSize(188))
        setBigYearFontSize(getElementSize(154))
        setSmallYearFontSize(getElementSize(66))
        setYearMoveGap(getElementSize(240))
      }
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  return (
    <div
      className={`${lessModule.experience} ${isMobile() ? lessModule.mobile : ''}`}
      ref={extWrapRef}
      data-scroll
      data-scroll-offset="-300,0"
      data-scroll-call={`preloadImg, expImg, ${lessModule.experience}`}
    >
      <ContentTitle
        type="experience"
        style={{
          marginLeft: isMobile() ? '.26rem' : '1.16rem',
          marginTop: titleMarginTop,
        }}
        mobile={isMobile()}
      />
      <div
        className={lessModule.years}
        style={{
          width: '100%',
          height: `${parseInt(bigYearFontSize) * 1.168}px`,
        }}
        onMouseEnter={onMouseEnterYear}
        onMouseLeave={onMouseLeaveYear}
      >
        {
          dataSource.current.map((item, index) => (
            <div
              className={`${lessModule.yearItem} ${lessModule.yearItem}-${item.id}`}
              key={item.id}
              style={{
                fontSize: bigYearFontSize,
              }}
            >
              <div
                onClick={() => onClickYearItem(index)}
                className={lessModule.yearInner}
              >
                {
                  item.year
                }
              </div>
            </div>
          ))
        }
      </div>
      <div
        className={`${lessModule.timeAxis}`}
      >
        {
          isMobile() ? (
            <ImgTimeAxisMobile />
          ) : (
            <ImgTimeAxis />
          )
        }
      </div>
      <div
        className={lessModule.desc}
      >
        <img
          src={descDataSource.current.img}
          className={`${lessModule.descImg}`}
          style={{
            height: descDataSource.current.imgHeight,
          }}
          alt=""
        />

        <div
          className={lessModule.descText}
        >
          {descDataSource.current.desc}
        </div>
      </div>
      {
        !isMobile() && (
          <div
            className={`${lessModule.toggleIcon} ${lessModule[`toggleIcon${arrowDir.current}`]}`}
            onClick={onNextAnimate}
            style={{
              ...btnStyle,
              display: btnVisible && !mouseOnYears ? 'block' : 'none',
            }}
          />
        )
      }
      {
        isMobile() && (
          <>
            <div
              className={lessModule.btn}
              onClick={() => animate('pre')}
            >
              <BtnLeftMobile />
            </div>
            <div
              className={lessModule.btn}
              onClick={() => animate('next')}
            >
              <BtnRightMobile />
            </div>
          </>
        )
      }
    </div>
  )
}
