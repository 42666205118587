// import yun1 from '@/asset/落版云朵/yun1.png'
// import yun2 from '@/asset/落版云朵/yun2.png'

import { isMobile } from '@/utils/common'

export const cloudImgs = isMobile() ? {
  yun1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/Ql8DMN3pnwBlpDziVVDZd.png',
  yun2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/pDVP77RAFGHEh1SnESOfJ.png',
} : {
  yun1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/qV2N17GVbKjrKBE9BS0HaH.png',
  yun2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/6w2t82jAtAmCEQektOeVKE.png',
}
