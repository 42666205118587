import { techImgs } from '@/constants/imgs-src/tech'
import { techText } from '@/constants/texts'
import gsap from 'gsap'
import React, { useCallback, useRef, useState } from 'react'
import ContentTitle from '../content-title'
import lessModule from './index.module.less'

const techTextLength = techText.length
const duration = 0.8

export default function Tech() {
  const timelinsRef = useRef<gsap.core.Timeline[]>([])

  const [, forceUpdate] = useState({})

  const imgIndex = useRef(0)
  // const imgUnderIndex = useRef(0)
  const textIndex = useRef(0)
  const textUnderIndex = useRef(0)
  const disabledAnimate = useRef(false)

  const getImgIndex = useCallback(() => {
    let index = imgIndex.current % techTextLength
    if (index < 0) {
      index += techTextLength
    }
    return index
  }, [])

  const getTextIndex = useCallback(() => {
    let index = textIndex.current % techTextLength
    if (index < 0) {
      index += techTextLength
    }
    return index
  }, [])

  const getTextUnderIndex = useCallback(() => {
    let index = textUnderIndex.current % techTextLength
    if (index < 0) {
      index += techTextLength
    }
    return index
  }, [])

  const animate = useCallback(async (type: 'pre' | 'next') => {
    if (disabledAnimate.current) {
      return
    }
    disabledAnimate.current = true

    // 图片动画
    const timelineImg = gsap.timeline()
    timelinsRef.current.push(timelineImg)
    timelineImg.to(
      `.${lessModule.img}`,
      {
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
        duration: 0,
        onComplete() {
          if (type === 'pre') {
            imgIndex.current -= 1
          } else {
            imgIndex.current += 1
          }
          forceUpdate({})
        },
      },
    )
    timelineImg.to(
      `.${lessModule.img}`,
      {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
        duration,
        // ease: 'none',
      },
      '>',
    )
    // 文字动画
    if (type === 'pre') {
      textUnderIndex.current -= 1
    } else {
      textUnderIndex.current += 1
    }
    forceUpdate({})
    const timelineText = gsap.timeline()
    timelinsRef.current.push(timelineText)
    timelineText.to(
      `.${lessModule.textWrap}`,
      {
        opacity: 0,
        duration: duration - 0.4,
      },
    )

    // 文字动画
    const timelineTextUnder = gsap.timeline()
    timelinsRef.current.push(timelineTextUnder)
    timelineTextUnder.to(
      `.${lessModule.textUnderWrap}`,
      {
        opacity: 1,
        duration,
        onComplete() {
          timelineText.to(
            `.${lessModule.textWrap}`,
            {
              opacity: 1,
              duration: 0,
            },
          )
          if (type === 'pre') {
            textIndex.current -= 1
          } else {
            textIndex.current += 1
          }
          forceUpdate({})
        },
      },
    )
    timelineTextUnder.to(
      `.${lessModule.textUnderWrap}`,
      {
        opacity: 0,
        duration: 0,
      },
    )

    await Promise.all(timelinsRef.current)
    timelinsRef.current = []
    disabledAnimate.current = false
  }, [])

  const onPreImg = useCallback(() => {
    animate('pre')
  }, [animate])

  const onNextImg = useCallback(() => {
    animate('next')
  }, [animate])

  return (
    <div
      className={lessModule.tech}
      style={{
        backgroundImage: `url(${techImgs.imgBg})`,
      }}
      data-scroll
      data-scroll-offset="-600,0"
      data-scroll-call={`preloadImg, techImg, ${lessModule.tech}`}
    >
      <img
        src={techText[getImgIndex()].img}
        className={lessModule.img}
        alt=""
        style={{
          width: techText[getImgIndex()].imgWidth,
          top: techText[getImgIndex()].imgTop,
          right: techText[getImgIndex()].imgRight,
        }}
      />
      <div
        className={lessModule.content}
      >
        <ContentTitle
          type="tech"
          style={{
            marginBottom: '.38rem',
            marginTop: '1.31rem',
          }}
        />
        <div className={lessModule.textWrap}>
          <div
            className={lessModule.bigText}
          >
            <div
              className={lessModule.line}
            >{techText[getTextIndex()].line1}</div>
            <div
              className={lessModule.line}
            >{techText[getTextIndex()].line2}</div>
            <div
              className={lessModule.line}
            >{techText[getTextIndex()].line3}</div>
          </div>
          <div
            className={lessModule.date}
          >
            {techText[getTextIndex()].line4}
          </div>
        </div>
        <div className={lessModule.textUnderWrap}>
          <div
            className={lessModule.bigText}
          >
            <div
              className={lessModule.line}
            >{techText[getTextUnderIndex()].line1}</div>
            <div
              className={lessModule.line}
            >{techText[getTextUnderIndex()].line2}</div>
            <div
              className={lessModule.line}
            >{techText[getTextUnderIndex()].line3}</div>
          </div>
          <div
            className={lessModule.date}
          >
            {techText[getTextUnderIndex()].line4}
          </div>
        </div>
        <div
          className={lessModule.btn}
        >
          <div
            className={`${lessModule.btnItem} ${lessModule.btnItemLeft}`}
            onClick={onPreImg}
          />
          <div
            className={`${lessModule.btnItem} ${lessModule.btnItemRight}`}
            onClick={onNextImg}
          />
        </div>
      </div>
    </div>
  )
}
