import { throttle } from 'lodash-es'
import { bussinessImgs } from '@/constants/imgs-src/bussiness'
import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { getElementSize, getHomeProperSize } from '@/utils/common'
import lessModule from './index.module.less'

const leftSideImgs = [
  // {
  //   src: bussinessImgs.nangua,
  //   link: 'https://www.nanguakexue.com/',
  // },
  {
    src: bussinessImgs.sky,
    link: 'https://mp.weixin.qq.com/s/2a4xraPYouur2ZKAPgbFYA',
  },
  {
    src: bussinessImgs.tiku,
    link: 'https://www.yuantiku.com/',
  },
  {
    src: bussinessImgs.haitun,
    link: 'https://www.haitunzixi.com/',
  },
]

// 高 / 宽 的比例
const leftSideImgsHeightRatio = [
  // 378 / 354,
  297 / 354,
  458 / 354,
  628 / 800,
]

const centerImgs = [
  {
    src: bussinessImgs.feixiang,
    link: 'https://www.feixiangxingqiu.com/',
  },
  {
    src: bussinessImgs.fudao,
    link: 'https://www.yuanfudao.com/',
  },
  {
    src: bussinessImgs.banma,
    link: 'https://banmaapp.com/',
  },
]

// 高 / 宽 的比例
const centerImgsRatio = [
  378 / 606,
  378 / 606,
  378 / 606,
]

const rightSideImgs = [
  {
    src: bussinessImgs.biancheng,
    link: 'https://www.ybccode.com/',
  },
  {
    src: bussinessImgs.coffee,
    link: 'https://mp.weixin.qq.com/s/l-uvfRJhuObI7OkzUxUI6g',
  },
  {
    src: bussinessImgs.moli,
    link: 'https://www.moliyuezi.com/',
  },
  {
    src: bussinessImgs.kousuan,
    link: 'https://xiaoyuankousuan.com/',
  },
]

// 高 / 宽 的比例
const rightSideImgsRatio = [
  278 / 354,
  278 / 354,
  278 / 354,
  278 / 354,
]

const columnBaseHeight = 1108
// 左边栏比中间和右边都高
const leftColumnBaseHeight = 1014

export const businessImgList = [
  ...leftSideImgs,
  ...centerImgs,
  ...rightSideImgs,
].map((item) => item.src)

export default function BusinessImg() {
  const [imgGap, setImgGap] = useState('18px')
  const [imgBorderRadius, setImgBorderRadius] = useState('7px')
  const [imgColumnHeight, setImgColumnHeight] = useState('1108px')
  const [leftImgColumnHeight, setLeftImgColumnHeight] = useState('1014px')
  const imgWrapRef = useRef<HTMLDivElement>(null)
  const wrapRef = useRef<HTMLDivElement>(null)
  const canAnimate = useRef(true)
  const [, forceUpdate] = useState({})
  const clearFnRef = useRef<(() => void)[]>([])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      setImgGap(getElementSize(18))
      setImgBorderRadius(getElementSize(7))
      setImgColumnHeight(getElementSize(columnBaseHeight))
      setLeftImgColumnHeight(getElementSize(leftColumnBaseHeight))
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    const fn = () => {
      // console.log('fn')
      while (clearFnRef.current.length) {
        const c = clearFnRef.current.pop()
        c && c()
      }
      // 左侧图片动画
      const leftImgTimeline1 = gsap.timeline()
      leftImgTimeline1.fromTo(
        `.${lessModule.sideLeft}`,
        {
          transform: 'translate(0, -100%)',
        },
        {
          transform: 'translate(0, -75%)',
          ease: 'none',
        },
      )
      const leftTrigger1 = ScrollTrigger.create({
        animation: leftImgTimeline1,
        trigger: `.${lessModule.imgWrap}`,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      })
      const leftImgTimeline2 = gsap.timeline()
      leftImgTimeline2.fromTo(
        `.${lessModule.sideLeft}`,
        {
          transform: 'translate(0, -75%)',
        },
        {
          transform: 'translate(0, 55%)',
          ease: 'none',
        },
      )
      const leftTrigger2 = ScrollTrigger.create({
        animation: leftImgTimeline2,
        trigger: `.${lessModule.imgWrap}`,
        start: 'top top',
        end: 'bottom+=100% top',
        scrub: true,
      })
      // 中间图片动画
      const centerImgTimeline1 = gsap.timeline()
      centerImgTimeline1.fromTo(
        `.${lessModule.centerImg}`,
        {
          transform: 'translate(0, 40%)',
        },
        {
          transform: 'translate(0, 0%)',
          ease: 'none',
        },
      )
      const centerTrigger1 = ScrollTrigger.create({
        animation: centerImgTimeline1,
        trigger: `.${lessModule.imgWrap}`,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      })
      const centerImgTimeline2 = gsap.timeline()
      centerImgTimeline2.fromTo(
        `.${lessModule.centerImg}`,
        {
          transform: 'translate(0, 0%)',
        },
        {
          transform: 'translate(0, -150%)',
          ease: 'none',
        },
      )
      const centerTrigger2 = ScrollTrigger.create({
        animation: centerImgTimeline2,
        trigger: `.${lessModule.imgWrap}`,
        start: 'top top',
        end: 'bottom+=100% top',
        scrub: true,
      })
      // 右侧图片动画
      const rightImgTimeline1 = gsap.timeline()
      rightImgTimeline1.fromTo(
        `.${lessModule.sideRight}`,
        {
          transform: 'translate(0, -100%)',
        },
        {
          transform: 'translate(0, -75%)',
          ease: 'none',
        },
      )
      const rightTrigger1 = ScrollTrigger.create({
        animation: rightImgTimeline1,
        trigger: `.${lessModule.imgWrap}`,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      })
      const rightImgTimeline2 = gsap.timeline()
      rightImgTimeline2.fromTo(
        `.${lessModule.sideRight}`,
        {
          transform: 'translate(0, -75%)',
        },
        {
          transform: 'translate(0, 45%)',
          ease: 'none',
        },
      )
      const rightTrigger2 = ScrollTrigger.create({
        animation: rightImgTimeline2,
        trigger: `.${lessModule.imgWrap}`,
        start: 'top top',
        end: 'bottom+=100% top',
        scrub: true,
      })
      const c = () => {
        leftTrigger1.kill()
        leftTrigger2.kill()
        centerTrigger1.kill()
        centerTrigger2.kill()
        rightTrigger1.kill()
        rightTrigger2.kill()

        leftImgTimeline1.kill()
        leftImgTimeline2.kill()
        centerImgTimeline1.kill()
        centerImgTimeline2.kill()
        rightImgTimeline1.kill()
        rightImgTimeline2.kill()
      }
      return clearFnRef.current.push(c)
    }
    const onResize = throttle(() => {
      const { winHeight } = getHomeProperSize()
      const colHeight = getElementSize(columnBaseHeight)
      canAnimate.current = parseInt(colHeight) >= winHeight
      // console.log('winHeight', parseInt(colHeight), winHeight, canAnimate.current)

      forceUpdate({})
      setTimeout(() => {
        // console.log('canAnimate.current', canAnimate.current)
        if (canAnimate.current) {
          fn()
        }
      }, 100)
    }, 300)
    onResize()
    window.addEventListener('resize', onResize, false)
    const clearList = clearFnRef.current
    return () => {
      clearList.forEach((c) => c())
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  return (
    <div
      className={`${lessModule.imgWrap} ${canAnimate.current ? '' : lessModule.notAnimate}`}
      data-scroll
      ref={wrapRef}
    >
      <div
        className={lessModule.imgContent}
        data-scroll
        style={{
          height: canAnimate.current ? `${parseInt(imgColumnHeight) * 2}px` : `${parseInt(imgColumnHeight)}px`,
        }}
      >
        <div
          className={lessModule.imgStickyParent}
          data-scroll
          style={{
            // height: `${parseInt(imgColumnHeight) * 4}px`,
            height: canAnimate.current ? `${parseInt(imgColumnHeight) * 4}px` : `${parseInt(imgColumnHeight)}px`,
            top: canAnimate.current ? `-${parseInt(imgColumnHeight)}px` : 0,
            bottom: canAnimate.current ? `-${parseInt(imgColumnHeight)}px` : 0,
          }}
        >
          <div
            className={lessModule.imgSticky}
            data-scroll
            data-scroll-sticky={canAnimate.current ? 'true' : undefined}
            data-scroll-target={canAnimate.current ? `.${lessModule.imgStickyParent}` : undefined}
          >
            <div
              ref={imgWrapRef}
              className={lessModule.imgInner}
              data-scroll
              data-scroll-offset="0,0"
              data-scroll-call={`preloadImg, businessImg, ${lessModule.imgInner}`}
            >
              <div
                className={lessModule.sideLeft}
                style={{
                  marginRight: imgGap,
                  height: `${parseInt(leftImgColumnHeight)}px`,
                  // 左边栏的宽度是 （左栏+中间栏+右边栏） 的 3.71 份之 1
                  width: `calc((100% - ${parseInt(imgGap) * 2}px) / 3.71)`,
                }}
              >
                {
                [...leftSideImgs, ...leftSideImgs].map((item, index) => (
                  <img
                    data-src={item.src}
                    key={index}
                    className={`${lessModule.img} lazy`}
                    alt=""
                    style={{
                      borderRadius: imgBorderRadius,
                      marginBottom: index === leftSideImgs.length * 2 - 1 ? 0 : imgGap,
                      height: `calc((${getHomeProperSize().winWidth}px - ${parseInt(imgGap) * 2}px) / 3.71 * ${leftSideImgsHeightRatio[index % leftSideImgsHeightRatio.length]})`,
                    }}
                    onClick={() => window.open(item.link, '_blank')}
                  />
                ))
              }
              </div>
              <div
                className={lessModule.centerImg}
                style={{
                  marginRight: imgGap,
                  height: `${parseInt(imgColumnHeight)}px`,
                  // 中间栏的宽度是 （左栏+中间栏+右边栏） 的 3.71 份之 1.71
                  width: `calc((100% - ${parseInt(imgGap) * 2}px) / 3.71 * 1.71)`,
                }}
              >
                {
                [...centerImgs, ...centerImgs].map((item, index) => (
                  <img
                    key={index}
                    data-src={item.src}
                    className={`${lessModule.img} lazy`}
                    alt=""
                    style={{
                      marginBottom: index === centerImgs.length * 2 - 1 ? 0 : imgGap,
                      borderRadius: imgBorderRadius,
                      height: `calc((${getHomeProperSize().winWidth}px - ${parseInt(imgGap) * 2}px) / 3.71 * 1.71 * ${centerImgsRatio[index % centerImgsRatio.length]})`,
                    }}
                    onClick={() => window.open(item.link, '_blank')}
                  />
                ))
              }
              </div>
              <div
                className={lessModule.sideRight}
                style={{
                  height: `${parseInt(imgColumnHeight)}px`,
                  // 左边栏的宽度是 （左栏+中间栏+右边栏） 的 3.71 份之 1
                  width: `calc((100% - ${parseInt(imgGap) * 2}px) / 3.71)`,
                }}
              >
                {
                [...rightSideImgs, ...rightSideImgs].map((item, index) => (
                  <img
                    key={index}
                    data-src={item.src}
                    className={`${lessModule.img} lazy`}
                    alt=""
                    style={{
                      marginBottom: index === rightSideImgs.length * 2 - 1 ? 0 : imgGap,
                      // height: `calc(100% * ${rightSideImgsRatio[index % rightSideImgsRatio.length]})`,
                      borderRadius: imgBorderRadius,
                      height: `calc((${getHomeProperSize().winWidth}px - ${parseInt(imgGap) * 2}px) / 3.71 * ${rightSideImgsRatio[index % rightSideImgsRatio.length]})`,
                    }}
                    onClick={() => window.open(item.link, '_blank')}
                  />
                ))
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
