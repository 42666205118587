import ContentTitle from '@/components/content-title'
import React, { CSSProperties, useCallback, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import { newsText } from '@/constants/texts'
import { padNumber } from '@/utils/common'
import { useTouchX } from '@/hooks/common'
import lessModule from './index.module.less'

const newsTextLength = newsText.length
const progressPerSwiper = 1 / (newsTextLength - 1)

const swiperBreakPoints = {
  // when window width is >= 320px
  375: {
    spaceBetween: 10,
    slidesOffsetBefore: -9,
  },
  414: {
    spaceBetween: 11,
    slidesOffsetBefore: -10,
  },
  // when window width is >= 480px
  480: {
    spaceBetween: 13,
    slidesOffsetBefore: -12,
  },
  // when window width is >= 640px
  640: {
    spaceBetween: 17,
    slidesOffsetBefore: -16,
  },
  768: {
    spaceBetween: 20,
    slidesOffsetBefore: -19,
  },
}

export default function NewsMobile() {
  const swiperIndexRef = useRef(0)
  const swiperNextIndexRef = useRef(0)
  const swiperStartIndexRef = useRef(0)
  const swiperTouchStartProgressRef = useRef(0)
  const swiperProgrressRef = useRef(0)
  const [curSwiperTextStyle, setCurSwiperTextStyle] = useState<CSSProperties>({})
  const [nextSwiperTextStyle, setNextSwiperTextStyle] = useState<CSSProperties>({})

  const swiperWrapRef = useRef<HTMLDivElement>(null)
  useTouchX(swiperWrapRef)

  const onSwiperStartChange = useCallback((s: SwiperClass, e) => {
    swiperTouchStartProgressRef.current = s.progressLoop
    swiperStartIndexRef.current = s.realIndex
  }, [])

  const onSwiperProgress = useCallback((s: SwiperClass) => {
    swiperProgrressRef.current = s.progressLoop
    const startProgress = swiperTouchStartProgressRef.current
    let progressDiff = swiperProgrressRef.current - startProgress
    let transition = '.1s'
    // 当前是最后一张图片
    if (swiperStartIndexRef.current === newsTextLength - 1) {
      // 最后一张图片的按压下时的 progressLoop 是 1，同时往第一张图片滑动时 progress 是从 0 开始到 progressPerSwiper
      if (s.swipeDirection === 'next') {
        // 那么往最后一张图片滑动的进度就是滑动进度本身，不用减去任何值（也可以说是减去 0）
        progressDiff = s.progressLoop
        // 从最后一张图片到第一张图片的最后阶段，会有一个进度从某个值突然跳到 0 的过程，这个过程要去掉动画，防止闪烁
        if (progressDiff === 0) {
          transition = 'none'
          setCurSwiperTextStyle({
            opacity: 0,
            transition,
          })
          setNextSwiperTextStyle({
            opacity: 1,
            transition,
          })
          return
        }
      }
    }
    // 当前是第一张图片，且往最后一张图片画的滑动
    if (swiperStartIndexRef.current === 0) {
      // 往最后一张图片滑动时，是从 progressPerSwiper 到 0 的过程
      if (s.swipeDirection === 'prev') {
        progressDiff = s.progressLoop - progressPerSwiper
        // 从第一张图片到最后一张张图片的最后阶段，会有一个进度从某个值突然跳到 1 的过程，这个过程要去掉动画，防止闪烁
        if (Math.abs(progressDiff) === progressPerSwiper) {
          transition = 'none'
          setCurSwiperTextStyle({
            opacity: 0,
            transition,
          })
          setNextSwiperTextStyle({
            opacity: 1,
            transition,
          })
          return
        }
      }
    }
    // 往下一张图片滑动
    if (s.swipeDirection === 'next') {
      swiperNextIndexRef.current = (swiperIndexRef.current + 1) % newsTextLength
    } else {
      // 往上一张图片滑动
      swiperNextIndexRef.current = (swiperIndexRef.current - 1) % newsTextLength
      if (swiperNextIndexRef.current < 0) {
        swiperNextIndexRef.current += newsTextLength
      }
    }
    let animatePercent = Math.abs(progressDiff) / progressPerSwiper
    animatePercent = animatePercent > 1 ? 1 : animatePercent
    setCurSwiperTextStyle({
      opacity: 1 - animatePercent,
      transition,
    })
    setNextSwiperTextStyle({
      opacity: animatePercent,
      transition,
    })
  }, [])

  const onSwiperIndexChange = useCallback((s: SwiperClass) => {
    swiperIndexRef.current = s.realIndex
    setCurSwiperTextStyle({
      opacity: 1,
      transition: 'none',
    })
    setNextSwiperTextStyle({
      opacity: 0,
      transition: 'none',
    })
    // forceUpdate({})
  }, [])

  return (
    <div className={lessModule.news}>
      <ContentTitle
        type="news"
        mobile
        style={{
          marginBottom: '.19rem',
        }}
      />
      <div
        className={lessModule.swiperWrap}
        ref={swiperWrapRef}
      >
        <Swiper
          // @ts-ignore
          slidesPerView="auto"
          spaceBetween={10}
          loop
          centeredSlides
          className="mySwiper"
          slidesOffsetBefore={-9}
          breakpoints={swiperBreakPoints}
          watchSlidesProgress
          onTransitionEnd={onSwiperIndexChange}
          onTouchStart={onSwiperStartChange}
          onProgress={onSwiperProgress}
        >
          {
            newsText.map((item, index) => (
              <SwiperSlide
                key={item.id}
              >
                <div
                  className={lessModule.swiperItem}
                  onClick={() => {
                    window.open(item.link, '_blank')
                  }}
                >
                  <img
                    className={lessModule.img}
                    src={item.img}
                    alt=""
                  />
                  <span className={lessModule.index}>
                    {
                      padNumber(index + 1)
                    }
                    /
                    {
                      padNumber(newsTextLength)
                    }
                  </span>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
      <div
        className={lessModule.textWrap}
        style={curSwiperTextStyle}
      >
        <div className={lessModule.bigText}>{`${newsText[swiperIndexRef.current].line1}${newsText[swiperIndexRef.current].line2}`}</div>
        <div className={lessModule.smallText}>{newsText[swiperIndexRef.current].line3}</div>
        <div className={lessModule.smallText}>{newsText[swiperIndexRef.current].line4}</div>
        <div className={lessModule.smallText}>{newsText[swiperIndexRef.current].line5}</div>
        <div className={lessModule.smallText}>{newsText[swiperIndexRef.current].line6}</div>
        <div className={lessModule.date}>{newsText[swiperIndexRef.current].year}</div>
      </div>
      <div
        className={lessModule.nextTextWrap}
        style={nextSwiperTextStyle}
      >
        <div className={lessModule.bigText}>{`${newsText[swiperNextIndexRef.current].line1}${newsText[swiperNextIndexRef.current].line2}`}</div>
        <div className={lessModule.smallText}>{newsText[swiperNextIndexRef.current].line3}</div>
        <div className={lessModule.smallText}>{newsText[swiperNextIndexRef.current].line4}</div>
        <div className={lessModule.smallText}>{newsText[swiperNextIndexRef.current].line5}</div>
        <div className={lessModule.smallText}>{newsText[swiperNextIndexRef.current].line6}</div>
        <div className={lessModule.date}>{newsText[swiperNextIndexRef.current].year}</div>
      </div>
    </div>
  )
}
