import React, { useEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { isMobile } from '@/utils/common'
import ContentTitle from '../content-title'
import lessModule from './index.module.less'

export default function BusinessTitle() {
  useEffect(() => {
    if (!isMobile()) {
      // 文字动画
      const titleTimeline = gsap.timeline()
      titleTimeline.fromTo(
        `.${lessModule.titleWrap}`,
        {
          transform: 'translate3d(0, 200px, 0)',
          opacity: 0,
        },
        {
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        },
      )
      ScrollTrigger.create({
        animation: titleTimeline,
        // scroller: '.scroll-container',
        trigger: `.${lessModule.titleWrap}`,
        start: 'top-=400px bottom',
        end: 'bottom bottom',
        // scrub: true,
        scrub: 0.5,
      })
    }
  }, [])

  return (
    <div
      className={`${lessModule.titleWrap} ${isMobile() ? lessModule.mobile : ''}`}
    >
      <ContentTitle
        type="business"
        style={{
          marginBottom: isMobile() ? '.2rem' : '.38rem',
          marginLeft: isMobile() ? 0 : undefined,
        }}
        mobile={isMobile()}
      />
      <div
        className={lessModule.desc}
      >涵盖多个业务板块</div>
      <div
        className={lessModule.desc}
      >服务超过数亿用户</div>
    </div>
  )
}
