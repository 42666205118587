import ContentTitle from '@/components/content-title'
import { techImgs } from '@/constants/imgs-src/tech'
import { techText } from '@/constants/texts'
import { padNumber } from '@/utils/common'
import gsap from 'gsap'
import { throttle } from 'lodash-es'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import lessModule from './index.module.less'

const techTextLength = techText.length
export default function TechMobile() {
  const dataSource = useRef([...techText])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [, forceUpdate] = useState({})
  const wrapRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const imgIndexRef = useRef(0)
  // const [contentHeight, setContentHeight] = useState('639px')

  const textIndexRef = useRef(0)

  const doAnimate = useCallback(() => {
    const textPercentage = 1 / (dataSource.current.length - 1) * 100
    const trigger = ScrollTrigger.create({
      // animation: timeline,
      trigger: `.${lessModule.innerWrap}`,
      start: `top+=${windowHeight}px bottom`,
      end: 'bottom bottom',
      scrub: true,
      onUpdate: (self) => {
        const { progress, direction } = self
        let percent = progress * 100 / textPercentage
        let index = Math.trunc(percent)
        percent -= index
        index += 1
        // console.log('1 - percent', 1 - percent)
        if (direction < 0) {
          index -= 1
        }
        if ((direction > 0 && 1 - percent <= 0.5) || (direction < 0 && 1 - percent >= 0.5)) {
          // console.log('index,', index, imgIndexRef.current)
          if (index !== imgIndexRef.current) {
            imgIndexRef.current = index
            gsap.to(
              `.${lessModule.translateWrap}`,
              {
                duration: 0.5,
                transform: `translate3d(-${index * windowWidth}px, 0, 0)`,
              },
            )
            gsap.to(
              `.${lessModule.textWrap}`,
              {
                duration: 0.5,
                keyframes: {
                  '0%': {
                    opacity: 1,
                  },
                  '40%': {
                    opacity: 0,
                    onComplete() {
                      textIndexRef.current = index
                      forceUpdate({})
                    },
                  },
                  '60%': {
                    opacity: 0,
                  },
                  '100%': {
                    opacity: 1,
                  },
                },
              },
            )
          }
        }
      },
    })
    return () => {
      trigger.kill()
    }
  }, [windowHeight, windowWidth])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      // setContentHeight(getElementMobileSize(639))
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    let clear = () => {}

    const onResize = throttle(() => {
      clear()
      forceUpdate({})
      setTimeout(() => {
        clear = doAnimate()
      }, 100)
    }, 300)

    onResize()

    window.addEventListener('resize', onResize, false)

    return () => {
      clear()
      window.removeEventListener('resize', onResize, false)
    }
  }, [doAnimate])

  return (
    <div
      className={lessModule.tech}
      data-scroll
      data-scroll-offset="-300,0"
      data-scroll-call={`preloadImg, techImg, ${lessModule.tech}`}
      ref={wrapRef}
    >
      <div
        className={lessModule.innerWrap}
        style={{
          height: `${windowHeight * (dataSource.current.length - 2)}px`,
        }}
        ref={innerRef}
        data-scroll
      >
        <div
          className={lessModule.content}
          data-scroll
          data-scroll-sticky
          data-scroll-target={`.${lessModule.innerWrap}`}
          style={{
            backgroundImage: `url(${techImgs.imgBg})`,
            height: `${windowHeight}px`,
          }}
        >
          <ContentTitle
            type="tech"
            style={{
              marginBottom: '.3rem',
              paddingTop: '.8rem',
              marginLeft: '.2rem',
            }}
            mobile
          />
          <div className={lessModule.textWrap}>
            <div
              className={lessModule.bigText}
            >
              {techText[textIndexRef.current].mobileLine1}
            </div>
            <div
              className={lessModule.bigText}
            >
              {techText[textIndexRef.current].mobileLine2}
            </div>
            <div
              className={lessModule.bigText}
            >
              {techText[textIndexRef.current].mobileLine3}
            </div>
            <div
              className={lessModule.date}
            >
              {padNumber(textIndexRef.current + 1)}/{padNumber(techTextLength)}
            </div>
          </div>
          <div
            className={lessModule.translateWrap}
            style={{
              width: `${dataSource.current.length * 100}vw`,
            }}
          >
            {
              dataSource.current.map((item, index) => (
                <div
                  className={lessModule.translateInner}
                  key={item.id}
                  style={{
                    left: `${index * 100}vw`,
                  }}
                >
                  <img
                    className={`${lessModule.imgItem} ${`${lessModule.imgItem}-${item.id}`}`}
                    src={item.img}
                    alt=""
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}
