import { experienceText } from '@/constants/texts'
import { throttle } from 'lodash-es'
import gsap from 'gsap'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getElementMobileSize, mobileBaseWidth } from '@/utils/common'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import { useTouchX } from '@/hooks/common'
import ContentTitle from '../../content-title'
import lessModule from './index.module.less'

const duration = 0.5
const activeYearColor = '#1E2833'
const normalYearAxisHeight = 29
const activeYearAxisHeight = 48
const yearSepPreList = new Array(11).fill(1)
const yearSepLastList = new Array(6).fill(2)
const yearSepGap = 7.69

const experienceTextLenght = experienceText.length

export default function ExperienceMobile() {
  const [titleMarginTop, setTitleMarginTop] = useState('188px')
  const [bigYearFontSize, setBigYearFontSize] = useState('154px')
  const [smallYearFontSize, setSmallYearFontSize] = useState('66px')
  const [yearMoveGap, setYearMoveGap] = useState('138px')
  const [slidesOffsetBefore, setSlidesOffsetBefore] = useState('19.5px')
  const [pageWidth, setPageWidth] = useState(mobileBaseWidth)
  const [, forceUpdate] = useState({})

  const dataSource = useRef([experienceText[experienceTextLenght - 1], ...experienceText.slice(0, -1)])
  /** 描述文案的对象 */
  const descDataSource = useRef(experienceText[0])
  /** 下一个动画的描述文案的对象 */
  const nextDescDataSource = useRef(experienceText[0])

  const swiperWrapRef = useRef<HTMLDivElement>(null)
  /** 正在进行动画 */
  const isAnimating = useRef(false)
  /** swiper 实例 */
  const swiperInstanceRef = useRef<SwiperClass>(null)
  /** 执行向前动画的动画列表 */
  const swipeToPreAnimations = useRef<gsap.core.Tween[]>([])
  /** 执行向后动画的动画列表 */
  const swipeToNextAnimations = useRef<gsap.core.Tween[]>([])
  /** 动画的方向 */
  const swiperDirRef = useRef<'prev' | 'next'>()
  /** 是否通过点击来触发动画 */
  const isClickYearRef = useRef(false)
  /** 动画开始触摸的时间 */
  const swiperTouchStartTimeStamp = useRef(0)

  /** 点击的年份索引 */
  const clickYearIndexRef = useRef(0)
  /** 是否正在触摸屏幕 */
  const isTouchingRef = useRef(false)
  /** 触摸开始的索引 */
  const swiperTouchStartIndexRef = useRef(0)
  /** 当前 swiper 的索引 */
  const swiperIndexRef = useRef(0)
  /** 当前动画的进度 */
  const animatePercentRef = useRef(0)
  /** 开始触摸的水平位置 */
  const swiperStartTouchX = useRef(0)
  /** 上一个水平触摸位置 */
  const preTouchXRef = useRef(0)

  useTouchX(swiperWrapRef)

  const clearAnimations = useCallback(() => {
    const toBeKilled = [...swipeToPreAnimations.current, ...swipeToNextAnimations.current]
    toBeKilled.forEach((t) => {
      t.pause()
      t.kill()
    })
    swipeToPreAnimations.current = []
    swipeToNextAnimations.current = []
  }, [])

  /** 获取向后滑动的目标索引 */
  const getActiveIndexInNextAnimations = useCallback(() => swipeToNextAnimations.current.findIndex((t) => t.vars.color === activeYearColor), [])
  /** 获取向前滑动的目标索引 */
  const getActiveIndexInPreAnimations = useCallback(() => swipeToPreAnimations.current.findIndex((t) => t.vars.color === activeYearColor), [])

  // swiper 的第一张图片从屏幕左侧开始排列，而激活的年份位于屏幕中间，所以激活的年份为 swiper 当前索引 + 1
  const getYearActiveIndex = useCallback(() => (swiperIndexRef.current + 1) % experienceTextLenght, [])

  const getShouldActiveYearIndex = useCallback((type?: 'refresh' | 'next' | 'pre') => {
    const activeIndex = getYearActiveIndex()
    let shouldActive = activeIndex
    if (type === 'next') {
      shouldActive++
    } else if (type === 'pre') {
      shouldActive--
    }
    if (shouldActive >= experienceTextLenght) {
      shouldActive %= experienceTextLenght
    }
    if (shouldActive < 0) {
      shouldActive = experienceTextLenght - 1
    }
    return shouldActive
  }, [getYearActiveIndex])

  const getAnimations = useCallback((type?: 'refresh' | 'next' | 'pre', toGetIndex?: number, preActiveIndex?: number) => {
    clearAnimations()
    const activeIndex = getYearActiveIndex()
    let nextActiveIndex = activeIndex
    if (toGetIndex !== undefined) {
      nextActiveIndex = toGetIndex
    } else {
      nextActiveIndex = getShouldActiveYearIndex(type)
    }
    // 年份动画
    const timelines = dataSource.current.map((item, index) => {
      let t: gsap.core.Tween
      if (index === nextActiveIndex) {
        t = gsap.fromTo(
          `.${lessModule.yearItem}-${item.id}`,
          {
            fontSize: parseInt(smallYearFontSize),
            color: 'rgba(120,128,128,0.25)',
          },
          {
            fontSize: parseInt(bigYearFontSize),
            color: activeYearColor,
            duration: type === 'refresh' ? 0 : duration,
            paused: type !== 'refresh',
            ease: isTouchingRef.current ? 'none' : undefined,
          },
        )
      } else {
        // eslint-disable-next-line no-lonely-if
        if (index === preActiveIndex) {
          t = gsap.fromTo(
            `.${lessModule.yearItem}-${item.id}`,
            {
              fontSize: parseInt(bigYearFontSize),
              color: activeYearColor,
            },
            {
              fontSize: parseInt(smallYearFontSize),
              color: 'rgba(120,128,128,0.25)',
              duration: type === 'refresh' ? 0 : duration,
              paused: type !== 'refresh',
              ease: isTouchingRef.current ? 'none' : undefined,
            },
          )
        } else {
          t = gsap.to(
            `.${lessModule.yearItem}-${item.id}`,
            {
              fontSize: parseInt(smallYearFontSize),
              color: 'rgba(120,128,128,0.25)',
              duration: type === 'refresh' ? 0 : duration,
              paused: type !== 'refresh',
              ease: isTouchingRef.current ? 'none' : undefined,
            },
          )
        }
      }
      return t
    })
    // 年份的时间轴
    const timelinesAxis = dataSource.current.map((item, index) => {
      let t: gsap.core.Tween
      if (index === nextActiveIndex) {
        t = gsap.fromTo(
          `.${lessModule.yearSep}-${item.id}`,
          {
            height: `${normalYearAxisHeight * pageWidth / mobileBaseWidth}px`,
          },
          {
            height: `${activeYearAxisHeight * pageWidth / mobileBaseWidth}px`,
            duration: type === 'refresh' ? 0 : duration,
            paused: type !== 'refresh',
            ease: isTouchingRef.current ? 'none' : undefined,
          },
        )
      } else {
        // eslint-disable-next-line no-lonely-if
        if (index === preActiveIndex) {
          t = gsap.fromTo(
            `.${lessModule.yearSep}-${item.id}`,
            {
              height: `${activeYearAxisHeight * pageWidth / mobileBaseWidth}px`,
            },
            {
              height: `${normalYearAxisHeight * pageWidth / mobileBaseWidth}px`,
              duration: type === 'refresh' ? 0 : duration,
              paused: type !== 'refresh',
              ease: isTouchingRef.current ? 'none' : undefined,
            },
          )
        } else {
          t = gsap.to(
            `.${lessModule.yearSep}-${item.id}`,
            {
              height: `${normalYearAxisHeight * pageWidth / mobileBaseWidth}px`,
              duration: type === 'refresh' ? 0 : duration,
              paused: type !== 'refresh',
              ease: isTouchingRef.current ? 'none' : undefined,
            },
          )
        }
      }
      return t
    })
    const descOnComplete = () => {
      descDataSource.current = dataSource.current[nextActiveIndex]
      forceUpdate({})
      setTimeout(() => {
        gsap.set(
          `.${lessModule.desc}`,
          {
            opacity: 1,
          },
        )
      }, 0)
    }
    const descTs: gsap.core.Tween[] = []
    if (type !== 'refresh') {
      // 描述信息动画
      const descT = gsap.to(
        `.${lessModule.desc}`,
        {
          keyframes: [
            {
              opacity: 1,
              duration: 0,
            },
            {
              opacity: 0,
              duration: duration / 2,
            },
            {
              opacity: 0,
              duration: duration / 2,
            },
          ],
          duration,
          paused: true,
          ease: isTouchingRef.current ? 'none' : undefined,
          onUpdate() {
            if (Math.abs(descT.progress() - 1) <= 0.05) {
              descOnComplete()
            }
          },
          onComplete() {
            descOnComplete()
          },
        },
      )
      const nextDescOnComplete = () => {
        setTimeout(() => {
          gsap.set(
            `.${lessModule.descBottom}`,
            {
              opacity: 0,
            },
          )
        }, 0)
      }
      const nextDescT = gsap.to(
        `.${lessModule.descBottom}`,
        {
          keyframes: [
            {
              opacity: 0,
              duration: 0,
            },
            {
              opacity: 0,
              duration: duration / 2,
            },
            {
              opacity: 1,
              duration: duration / 2,
            },
          ],
          duration,
          paused: true,
          ease: isTouchingRef.current ? 'none' : undefined,
          onUpdate() {
            if (Math.abs(nextDescT.progress() - 1) <= 0.05) {
              nextDescOnComplete()
            }
          },
          onComplete() {
            nextDescOnComplete()
          },
        },
      )
      descTs.push(descT)
      descTs.push(nextDescT)
    }
    return [...timelines, ...timelinesAxis, ...descTs]
  }, [clearAnimations, getYearActiveIndex, getShouldActiveYearIndex, smallYearFontSize, bigYearFontSize, pageWidth])

  const doAnimate = useCallback(async (type?: 'refresh' | 'next' | 'pre') => {
    const timelines = getAnimations(type)
    await Promise.all([...timelines])
    if (type !== 'refresh') {
      isAnimating.current = false
    }
    return timelines
  }, [getAnimations])

  const animate = useCallback(async (type?: 'refresh' | 'next' | 'pre') => {
    if (type !== 'refresh' && isAnimating.current) {
      return []
    }
    if (type !== 'refresh') {
      isAnimating.current = true
    }

    forceUpdate({})
    const result = await doAnimate(type)
    return result
  }, [doAnimate])

  const onClickYearItemFN = useCallback((index: number) => {
    if (isAnimating.current) {
      return
    }
    clickYearIndexRef.current = index
    isAnimating.current = true
    isClickYearRef.current = true
    const activeIndex = getYearActiveIndex()

    if (index === activeIndex) {
      isAnimating.current = false
      isClickYearRef.current = false
      return
    }
    if (activeIndex === experienceTextLenght - 1 && index === 0) {
      swiperDirRef.current = 'next'
    } else if (activeIndex === 0 && index === experienceTextLenght - 1) {
      swiperDirRef.current = 'prev'
    } else if (index < activeIndex) {
      swiperDirRef.current = 'prev'
    } else {
      swiperDirRef.current = 'next'
    }

    if (swiperDirRef.current === 'next') {
      swiperInstanceRef.current?.slideNext()
    } else {
      swiperInstanceRef.current?.slidePrev()
    }
    // 点击的话，直接动画到下一张
    clearAnimations()
    nextDescDataSource.current = dataSource.current[clickYearIndexRef.current]
    forceUpdate({})
    if (swiperDirRef.current === 'next') {
      swipeToNextAnimations.current = getAnimations('next', clickYearIndexRef.current)
      swipeToNextAnimations.current.forEach((t) => {
        t.resume()
      })
    } else {
      swipeToPreAnimations.current = getAnimations('pre', clickYearIndexRef.current)
      swipeToPreAnimations.current.forEach((t) => {
        t.resume()
      })
    }
  }, [clearAnimations, getAnimations, getYearActiveIndex])
  // 每个动画期间只能点击一次
  const onClickYearItem = useMemo(() => {
    const fn = throttle(onClickYearItemFN, duration + 0.2)
    return fn
  }, [onClickYearItemFN])

  const onSwiper = useCallback((e: SwiperClass) => {
    swiperInstanceRef.current = e
  }, [])

  const onSwiperTouchStart = useCallback((s: SwiperClass, e: MouseEvent | TouchEvent | PointerEvent) => {
    isTouchingRef.current = true
    if (isAnimating.current) {
      return
    }
    swiperTouchStartTimeStamp.current = +new Date()
    swiperTouchStartIndexRef.current = s.realIndex
    swiperStartTouchX.current = s.touches.currentX
    preTouchXRef.current = s.touches.currentX
  }, [])
  const onSwiperTouchMove = useCallback((s: SwiperClass, e: MouseEvent | TouchEvent | PointerEvent) => {
    // 如果是点击，直接动画过去就行
    if (isClickYearRef.current) {
      return
    }
    const curTranslate = s.touches.currentX
    if (Math.trunc(curTranslate) === Math.trunc(swiperStartTouchX.current)) {
      return
    }
    if (Math.trunc(curTranslate) === Math.trunc(preTouchXRef.current)) {
      return
    }
    // 正在移动的方向，从手指按下到抬起，可以左右滑动
    const moveDir = curTranslate < preTouchXRef.current ? 'next' : 'prev'
    preTouchXRef.current = curTranslate
    // 整体移动的方向
    const dir = curTranslate < swiperStartTouchX.current ? 'next' : 'prev'

    const translatePercent = Math.abs(curTranslate - swiperStartTouchX.current) / parseInt(yearMoveGap)
    const diffSlide = Math.floor(translatePercent)
    let animatePercent = translatePercent - diffSlide
    if (dir === 'next') {
      // 首次移动
      if (!swipeToNextAnimations.current.length) {
        clearAnimations()
        swipeToNextAnimations.current = getAnimations('next')
        nextDescDataSource.current = dataSource.current[getActiveIndexInNextAnimations()]
      }
      swiperIndexRef.current = (swiperTouchStartIndexRef.current + diffSlide) % experienceTextLenght
      if (dir === moveDir) {
        const curAnimate = getActiveIndexInNextAnimations()
        const shouldActive = getShouldActiveYearIndex('next')
        if (curAnimate !== shouldActive) {
          clearAnimations()
          swipeToNextAnimations.current = getAnimations('next', shouldActive, curAnimate)
          descDataSource.current = dataSource.current[curAnimate]
          nextDescDataSource.current = dataSource.current[getActiveIndexInNextAnimations()]
        }
      }

      // 往回滑了
      if (dir !== moveDir) {
        const curAnimate = getActiveIndexInNextAnimations()
        const fixIndex = getYearActiveIndex()
        if (curAnimate !== fixIndex) {
          clearAnimations()
          swipeToNextAnimations.current = getAnimations('next', fixIndex, curAnimate)
          descDataSource.current = dataSource.current[curAnimate]
          nextDescDataSource.current = dataSource.current[getActiveIndexInNextAnimations()]
        }
        animatePercent = 1 - animatePercent
      }
      // 执行动画
      swipeToNextAnimations.current.forEach((t) => {
        t.progress(animatePercent)
      })
    } else if (dir === 'prev') {
      // 首次移动
      if (!swipeToPreAnimations.current.length) {
        clearAnimations()
        swipeToPreAnimations.current = getAnimations('pre')
        nextDescDataSource.current = dataSource.current[getActiveIndexInPreAnimations()]
      }
      swiperIndexRef.current = swiperTouchStartIndexRef.current - diffSlide
      if (swiperIndexRef.current < 0) {
        swiperIndexRef.current = experienceTextLenght + swiperIndexRef.current
      }
      if (dir === moveDir) {
        const curAnimate = getActiveIndexInPreAnimations()
        const shouldActive = getShouldActiveYearIndex('pre')
        if (curAnimate !== shouldActive) {
          clearAnimations()
          swipeToPreAnimations.current = getAnimations('pre', shouldActive, curAnimate)
          descDataSource.current = dataSource.current[curAnimate]
          nextDescDataSource.current = dataSource.current[getActiveIndexInPreAnimations()]
        }
      }

      // 往回滑了
      if (dir !== moveDir) {
        const curAnimate = getActiveIndexInPreAnimations()
        const fixIndex = getYearActiveIndex()
        if (curAnimate !== fixIndex) {
          clearAnimations()
          swipeToPreAnimations.current = getAnimations('pre', fixIndex, curAnimate)
          descDataSource.current = dataSource.current[curAnimate]
          nextDescDataSource.current = dataSource.current[getActiveIndexInPreAnimations()]
        }
        animatePercent = 1 - animatePercent
      }
      // 执行动画
      swipeToPreAnimations.current.forEach((t) => {
        t.progress(animatePercent)
      })
    }
    animatePercentRef.current = animatePercent
    forceUpdate({})
  }, [yearMoveGap, clearAnimations, getAnimations, getActiveIndexInNextAnimations, getShouldActiveYearIndex, getYearActiveIndex, getActiveIndexInPreAnimations])

  const onSwiperTouchEnd = useCallback((s) => {
    isTouchingRef.current = false
    // 保证代码在点击事件之后执行
    setTimeout(() => {
      const dir = s.swipeDirection || swiperDirRef.current
      if (isClickYearRef.current) {
        // // 点击的话，直接动画到下一张
        // clearAnimations()
        // nextDescDataSource.current = dataSource.current[clickYearIndexRef.current]
        // forceUpdate({})
        // if (dir === 'next') {
        //   swipeToNextAnimations.current = getAnimations('next', clickYearIndexRef.current)
        //   swipeToNextAnimations.current.forEach((t) => {
        //     t.resume()
        //   })
        // } else {
        //   swipeToPreAnimations.current = getAnimations('pre', clickYearIndexRef.current)
        //   swipeToPreAnimations.current.forEach((t) => {
        //     t.resume()
        //   })
        // }
      } else {
        // 拖动结束，从当前进度动画到下一张
        const int = Math.floor(animatePercentRef.current)
        const percent = animatePercentRef.current - int
        const curActive = (s.realIndex + 1) % experienceTextLenght
        const nextActive = curActive
        descDataSource.current = dataSource.current[nextActive]
        nextDescDataSource.current = dataSource.current[nextActive]
        forceUpdate({})
        getAnimations(dir === 'next' ? dir : 'pre', nextActive).forEach((t) => {
          t.progress(percent > 0.5 ? percent : (1 - percent))
          t.resume()
        })
      }
    }, 50)
  }, [getAnimations])

  const onSwiperIndexChange = useCallback((s: SwiperClass) => {
    const now = +new Date()

    if (isTouchingRef.current) {
      return
    }
    const canEnd = (!isClickYearRef.current && !isTouchingRef.current) || (isClickYearRef.current && (now - swiperTouchStartTimeStamp.current >= (duration * 0.8) * 1000))
    if (canEnd) {
      isAnimating.current = false
      isClickYearRef.current = false
      swiperIndexRef.current = s.realIndex
      clearAnimations()
      descDataSource.current = dataSource.current[(s.realIndex + 1) % experienceTextLenght]
    }
    forceUpdate({})
  }, [clearAnimations])

  useEffect(() => {
    animate('refresh')
    // autoAnimate()
    return () => {
      // window.clearInterval(autoTimer.current)
    }
  }, [animate])

  useEffect(() => {
    // 屏幕宽度改变的时候，动态设置各个尺寸的大小
    const onResize = throttle(() => {
      setTitleMarginTop(getElementMobileSize(54))
      setBigYearFontSize(getElementMobileSize(85))
      setSmallYearFontSize(getElementMobileSize(31))
      setYearMoveGap(getElementMobileSize(138))
      setSlidesOffsetBefore(getElementMobileSize(-19.5))
      setPageWidth(document.documentElement.clientWidth)
    }, 100, { leading: true })

    window.addEventListener('resize', onResize, false)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  return (
    <div
      className={`${lessModule.experience} ${lessModule.mobile}`}
      data-scroll
      data-scroll-offset="-300,0"
      data-scroll-call={`preloadImg, expImg, ${lessModule.experience}`}
    >
      <ContentTitle
        type="experience"
        style={{
          marginLeft: '.26rem',
          marginTop: titleMarginTop,
        }}
        mobile
      />
      <div
        className={lessModule.years}
        ref={swiperWrapRef}
        style={{
          width: '100%',
          height: '1.02rem',
        }}
      >
        <Swiper
          // @ts-ignore
          slidesPerView="auto"
          spaceBetween={0}
          loop
          className="mySwiper"
          slidesOffsetBefore={parseInt(slidesOffsetBefore)}
          watchSlidesProgress
          onTransitionEnd={onSwiperIndexChange}
          onTouchStart={onSwiperTouchStart}
          onTouchMove={onSwiperTouchMove}
          onTouchCancel={onSwiperTouchEnd}
          onTouchEnd={onSwiperTouchEnd}
          preventInteractionOnTransition
          speed={duration * 1000}
          style={{
            height: '100%',
            overflowY: 'visible',
          }}
          onSwiper={onSwiper}
        >
          {
            dataSource.current.map((item, index) => (
              <SwiperSlide
                key={item.id}
                style={{
                  width: yearMoveGap,
                  paddingBottom: '2.75rem',
                }}
              >
                <div
                  className={`${lessModule.yearItem} ${lessModule.yearItem}-${item.id}`}
                  key={item.id}
                  style={{
                    fontSize: bigYearFontSize,
                    width: yearMoveGap,
                  }}
                >
                  <div
                    onClick={(e) => {
                      onClickYearItem(index)
                    }}
                    className={lessModule.yearInner}
                  >
                    {
                      item.year
                    }
                  </div>
                </div>
                {
                  yearSepPreList.map((y, i) => (
                    <div
                      className={lessModule.smallYearPreSep}
                      key={`${y}-${i}`}
                      style={{
                        left: `${(69 - (yearSepPreList.length - i) * yearSepGap) / 100}rem`,
                      }}
                    />
                  ))
                }
                <div
                  key={`${item.id}-axis`}
                  className={`${lessModule.yearSep} ${lessModule.yearSep}-${item.id}`}
                />
                {
                  yearSepLastList.map((y, i) => (
                    <div
                      className={lessModule.smallYearLastSep}
                      key={`${y}-${i}`}
                      style={{
                        left: `${(69 + (i + 1) * yearSepGap) / 100}rem`,
                      }}
                    />
                  ))
                }
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
      <div className={lessModule.descWrap}>
        <div
          className={lessModule.desc}
        >
          <img
            src={descDataSource.current.img}
            className={`${lessModule.descImg}`}
            style={{
              height: descDataSource.current.imgHeight,
            }}
            alt=""
          />

          <div
            className={lessModule.descText}
          >
            {descDataSource.current.desc}
          </div>
        </div>
        <div
          className={`${lessModule.descBottom}`}
        >
          <img
            src={nextDescDataSource.current.img}
            className={`${lessModule.descImg}`}
            style={{
              height: nextDescDataSource.current.imgHeight,
            }}
            alt=""
          />

          <div
            className={lessModule.descText}
          >
            {nextDescDataSource.current.desc}
          </div>
        </div>
      </div>
    </div>
  )
}
