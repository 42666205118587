import { domMinHeightRatio, domMinWidth } from '@/constants/common'
import { throttle } from 'lodash-es'

const alreadyPreloadImgs: string[] = []

export function preloadImgFilterExist(list: string[]) {
  const needPreload = list.filter((item) => !alreadyPreloadImgs?.includes(item))
  needPreload.forEach((src) => {
    preloadImg(src)
  })
  alreadyPreloadImgs.push(...needPreload)
}

export const preloadImg = (src: string) => new Promise((resolve) => {
  // const img = new Image()
  // img.onload = () => {
  //   resolve(src)
  // }
  // img.src = src

  const preloadLink = document.createElement('link')
  preloadLink.href = src
  preloadLink.rel = 'preload'
  preloadLink.as = 'image'
  document.head.appendChild(preloadLink)
})

export function onDocumentVisibleChange(callback: (hidden?: boolean, event?: Event) => void) {
  // 设置隐藏属性和改变可见属性的事件的名称
  let hidden = 'hidden'
  let visibilityChange = 'visibilitychange'
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
    // @ts-ignore
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  // 如果浏览器不支持addEventListener 或 Page Visibility API 给出警告
  // @ts-ignore
  if (typeof document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
    console.log('This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.')
    return () => {}
  }
  // 处理页面可见属性的改变
  const onVisibleChange = (e: any) => {
    // @ts-ignore
    callback.call(window, document[hidden], e)
  }
  document.addEventListener(visibilityChange, onVisibleChange, false)

  window.addEventListener('beforeunload', () => {
    document.removeEventListener(visibilityChange, onVisibleChange, false)
  })
  return () => {
    document.removeEventListener(visibilityChange, onVisibleChange, false)
  }
}

export function getElementScrollTop(ele: HTMLElement | null) {
  let temp: HTMLElement | null = ele
  let top = 0

  while (temp) {
    top += temp.offsetTop
    temp = temp.parentElement
  }
  return top
}

export function getHomeProperSize() {
  const winWidth = Math.max(document.documentElement.clientWidth, domMinWidth)
  const winHeight = Math.max(window.innerHeight, domMinHeightRatio * winWidth)
  return {
    winWidth,
    winHeight,
  }
}

// 以 1280 * 720的设计稿为标准，传入元素的尺寸，返回在当前屏幕大小下的实际尺寸
export function getElementSize(size: number, sizeInHeight?: number) {
  const winWidth = Math.max(document.documentElement.clientWidth, domMinWidth)
  let width = 0
  if (sizeInHeight !== undefined) {
    // 小于 1 说明已经是百分比了，不然需要转成百分比
    width = size < 1 ? size : size / pcBaseWidth
  } else {
    width = size / pcBaseWidth
  }

  return `${Math.min(Math.round(width * winWidth))}px`
}

export function getElementMobileSize(size: number, sizeInHeight?: number) {
  const winWidth = Math.max(document.documentElement.clientWidth, domMinWidth)
  let width = 0
  if (sizeInHeight !== undefined) {
    // 小于 1 说明已经是百分比了，不然需要转成百分比
    width = size < 1 ? size : size / mobileBaseWidth
  } else {
    width = size / mobileBaseWidth
  }

  return `${Math.min(Math.round(width * winWidth))}px`
}

export function getElementTranslateX(ele: Element) {
  const reg = /matrix\(\d*,\s\d*,\s\d*,\s\d*,\s(-?\d*(\.?\d*)),\s\d*\)/
  const trans = getComputedStyle(ele).transform
  const m = trans.match(reg)
  if (m) {
    return Number(m[1])
  }
  return 0
}

export function getElementTranslateY(ele: Element) {
  const reg = /matrix\(\d*,\s\d*,\s\d*,\s\d*,\s\d*,\s(-?\d*(\.?\d*))\)/
  const trans = getComputedStyle(ele).transform
  const m = trans.match(reg)
  if (m) {
    return Number(m[1])
  }
  return 0
}

export function padNumber(num: number) {
  if (num < 10) {
    return `0${num}`
  }
  return `${num}`
}

export const mobilePCThreshold = 768

export const mobileBaseWidth = 375

export const pcBaseWidth = 1280

let isMobileVar = document.documentElement.clientWidth <= mobilePCThreshold

const onResize = throttle(() => {
  isMobileVar = document.documentElement.clientWidth <= mobilePCThreshold
}, 200, { leading: true })

const onBeforeUnload = () => {
  window.removeEventListener('resize', onResize)
  window.removeEventListener('beforeunload', onBeforeUnload)
}

window.addEventListener('resize', onResize, false)
window.addEventListener('beforeunload', onBeforeUnload)

export function isMobile() {
  return isMobileVar
}

export const adjustRem = () => {
  const width = document.documentElement.clientWidth
  if (isMobile()) {
    document.documentElement.style.fontSize = `${(width / mobileBaseWidth * 100).toFixed(2)}px`
  } else {
    document.documentElement.style.fontSize = `${(width / pcBaseWidth * 100).toFixed(2)}px`
  }
}
